<template>
  <div class="doctor-info">
    <header class="doctor-info__header flex--space-between--center">
      <div class="icon-arrow-long-left custom-icon onlyXS" @click="returnToProfile"></div>
      <span class="header__title">
        {{ $t('dashboard.label.editPesronalInfo') }}
      </span>
      <div class="icon-close custom-icon otherXS" @mousedown="checkBeforeModalClose"></div>
    </header>
    <div class="divider otherXS"></div>
    <main ref="doctorEditSection" class="doctor-info__edit-section">
      <div class="change-photo">
        <div class="photo__container" :style="userPhotoStyles"></div>
        <change-photo :user-id="userId" @photo-uploaded="updatePhoto"></change-photo>
      </div>
      <div class="text-information-edit">
        <div class="doctor-urls-editor">
          <div class="section-title">
            {{ $t('dashboard.button.uniqueUrl') }}
          </div>
          <div class="doctor-slug-url">
            <a>www.skinxs.com/diag/</a>
            <div
              class="doctor-slug__container flex--space-between--center"
              :class="{ invisible: isDoctorSlugEditable }"
            >
              <span>{{ user.doctorSlug }}</span>
              <div class="icon-orange-pencil custom-icon" @click="toggleDoctorSlugChanging"></div>
            </div>
            <div class="doctor-slug-input" :class="{ invisible: !isDoctorSlugEditable }">
              <input
                v-model="updatedUser.doctorSlug"
                v-validate.disable="'required'"
                v-allow-only-reg-exp="validSlugRegExp"
                type="text"
                name="doctorSlug"
                class="doctor-info__input doctor-slug-input rounded-input input-focus-within"
                :class="{ 'error-highlight': doctorSlugInvalid }"
                @input="clearSlugErrors"
              />
              <p v-if="doctorSlugInvalid" class="error-message doctor-slug-error-message">
                <span class="fas fa-exclamation-triangle"></span>
                <span v-if="isDoctorSlugHasErrors">
                  {{ $t('dashboard.warning.requiredField') }}
                </span>
                <span v-if="slugInputError">{{ slugInputError }}</span>
              </p>
            </div>
          </div>

          <div class="language-and-personal-page flex space-between">
            <div class="language-selector mb15">
              <language-dropdown-input display-tooltip @language-select="updateLocale" />
            </div>

            <div class="doctor-personal-page">
              <div class="section-title doctor-website-label">
                <span>{{ $t('dashboard.label.website') }}</span>
                <div
                  v-tooltip="{
                    content: $t('dashboard.hint.uniqueUrl')
                  }"
                  class="icon-info custom-icon"
                ></div>
              </div>
              <input
                v-model="updatedUser.personalPage"
                class="doctor-personal-page__input doctor-info__input rounded-input input-focus-within"
                :class="{ 'error-highlight': !!websiteInputError }"
                @input="clearError"
              />
              <p v-if="websiteInputError" class="error-message">
                <span class="fas fa-exclamation-triangle"></span>
                <span>{{ websiteInputError }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="details-divider divider"></div>
        <div class="doctor-name__container">
          <div class="section-title">
            {{ $t('dashboard.doctorAccount.name') }}
          </div>
          <input
            v-model="updatedUser.name"
            v-validate.disable="'required'"
            name="doctorName"
            class="doctor-info__input rounded-input input-focus-within"
            :class="{ 'error-highlight': isDoctorNameHasErrors }"
            @input="clearNameErrors"
          />
          <p v-if="isDoctorNameHasErrors" class="error-message">
            <span class="fas fa-exclamation-triangle"></span>
            <span>{{ $t('dashboard.warning.requiredField') }}</span>
          </p>
        </div>
        <div class="details__email-selector">
          <div class="section-title">
            <span>{{ $t('dashboard.label.secondaryEmail') }}</span>
            <div
              v-tooltip="{ content: $t('dashboard.hint.secondaryEmailAddress') }"
              class="icon-info custom-icon"
            ></div>
          </div>
          <tag-selector
            :tags="updatedUser.secondaryEmails"
            :reg-exp="validEmailRegExp"
            :max-number-of-tags="10"
            :error-message="$t('dashboard.warning.provideValidEmail')"
            class="text-information-edit__tag-selector"
            @add-tag="addSecondaryEmail"
            @delete-tag="deleteSecondaryEmail"
          >
            <span slot="tag-icon" class="fas fa-envelope tag-icon mr10"></span>
          </tag-selector>
        </div>
        <div class="text-information-edit__details space-between">
          <div class="details__location location">
            <div class="section-title">
              <span>{{ $t('dashboard.label.personalPhoneNumber') }}</span>
              <div
                v-tooltip="{
                  content: $t('dashboard.hint.phoneNumberPersonal')
                }"
                class="icon-info custom-icon"
              ></div>
            </div>
            <phone-edit-field
              class="location__phone-field"
              :phone="updatedUser.privatePhone"
              :iso-code="updatedUser.privatePhoneIso"
              :phone-field-id="privatePhoneFieldName"
              :phone-field-name="privatePhoneFieldName"
              @update-iso-code="updateUserField('privatePhoneIso', $event)"
              @update-phone-number="updateUserField('privatePhone', $event)"
            />
          </div>
          <div class="details__location location">
            <div class="section-title">
              <span>{{ $t('dashboard.label.officePhoneNumber') }}</span>
              <div
                v-tooltip="{
                  content: $t('dashboard.hint.phoneNumber')
                }"
                class="icon-info custom-icon"
              ></div>
            </div>
            <phone-edit-field
              class="location__phone-field"
              :phone="updatedUser.officePhone"
              :iso-code="updatedUser.officePhoneIso"
              :is-required="true"
              :phone-field-id="officePhoneFieldName"
              :phone-field-name="officePhoneFieldName"
              @update-iso-code="updateUserField('officePhoneIso', $event)"
              @update-phone-number="updateUserField('officePhone', $event)"
            />
          </div>
        </div>
        <edit-clinics v-model="updatedUserClinics" />
      </div>
    </main>
    <div class="divider otherXS"></div>
    <footer class="doctor-info__footer">
      <a class="cancel-button otherXS" @mousedown="closeModal">
        {{ $t('dashboard.action.cancel') }}
      </a>
      <a class="cancel-button onlyXS" @click="cancelChangesAndReturnToProfile">
        {{ $t('dashboard.action.cancel') }}
      </a>
      <button class="save-changes-button rounded-button-white" @click="saveChanges">
        {{ $t('dashboard.button.saveChanges') }}
      </button>
    </footer>
    <unsaved-changes-modal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { pick, isEmpty, cloneDeep } from 'lodash';

import PerfectScrollbar from 'perfect-scrollbar';

import pageUnsavedChangesMixin from '@/modules/dashboard/components/mixins/pageUnsavedChangesMixin';

import EditClinics from '@/modules/dashboard/components/profile/EditClinics';
import PhoneEditField from '@/modules/dashboard/components/profile/PhoneEditField';
import ChangePhoto from '@/modules/dashboard/components/profile/ChangePhoto';
import TagSelector from '@/modules/dashboard/components/common/TagSelector';
import LanguageDropdownInput from '@/modules/dashboard/components/profile/LanguageDropdownInput';
import UnsavedChangesModal from '@/modules/dashboard/components/message-templates/UnsavedChangesModal';

import { updateUser, updateUserClinics } from '@/api';

import { updateDoctorSignature } from '@/modules/dashboard/api';

import { getDashboardLocale } from '@/utils';
import {
  buildDoctorImageURL,
  validateDoctorPhone,
  validateDoctorSlug,
  validateWebsite,
  getUrlWithProtocol,
  isMobileDevice
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

import {
  DEFAULT_ISO_CODE,
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  UNSAVED_CHANGES_INPUTS
} from '@/modules/dashboard/api/constants';

import { REQUEST_ERRORS, VALID_EMAIL_REG_EXP, VALID_SLUG_REG_EXP } from '@/constants';

export default {
  name: 'EditDoctorInfo',
  components: {
    EditClinics,
    UnsavedChangesModal,
    LanguageDropdownInput,
    TagSelector,
    ChangePhoto,
    PhoneEditField
  },
  mixins: [pageUnsavedChangesMixin],
  data() {
    return {
      updatedUser: {
        name: '',
        doctorSlug: '',
        personalPage: '',
        officePhoneIso: '',
        officePhone: null,
        privatePhoneIso: '',
        privatePhone: null,
        locale: '',
        secondaryEmails: []
      },
      updatedUserClinics: [],
      savedUserState: null,
      slugInputError: '',
      websiteInputError: '',
      isDoctorSlugEditable: false,
      validSlugRegExp: VALID_SLUG_REG_EXP,
      validEmailRegExp: VALID_EMAIL_REG_EXP,
      officePhoneFieldName: 'phone-office',
      privatePhoneFieldName: 'phone-private',
      scrollbar: null
    };
  },
  computed: {
    ...mapGetters({
      user: rootTypes.getters.GET_USER,
      userId: rootTypes.getters.GET_USER_ID,
      userLanguage: rootTypes.getters.GET_USER_LANGUAGE
    }),
    doctorSlugInvalid() {
      return this.isDoctorSlugHasErrors || !!this.slugInputError;
    },
    userPhotoStyles() {
      return { 'background-image': `url(${buildDoctorImageURL(this.user)})` };
    },
    isDoctorSlugHasErrors() {
      return this.errors.has('doctorSlug');
    },
    isDoctorNameHasErrors() {
      return this.errors.has('doctorName');
    },
    hasValidationError() {
      const isPhoneValid = validateDoctorPhone(this.updatedUser);

      return this.slugInputError || this.websiteInputError || !isPhoneValid;
    },
    defaultClinicIsoCode() {
      const { officePhoneIso, privatePhoneIso } = this.updatedUser;

      return officePhoneIso || privatePhoneIso || DEFAULT_ISO_CODE;
    }
  },
  created() {
    this.updatedUser = pick(this.user, [
      'id',
      'doctorSlug',
      'name',
      'secondaryEmails',
      'officePhoneIso',
      'privatePhoneIso',
      'personalPage',
      'autopilotEnabled',
      'twoFactorAuthenticationEnabled'
    ]);

    const {
      secondaryEmails,
      clinicDtos,
      officePhone,
      officePhoneIso,
      privatePhone,
      privatePhoneIso
    } = this.user;

    const userSecondaryEmails = secondaryEmails || [];
    const userClinics = clinicDtos || [];

    this.updatedUser.officePhone = officePhone || '';
    this.updatedUser.privatePhone = privatePhone || '';
    this.updatedUser.privatePhoneIso = privatePhoneIso || officePhoneIso;
    this.updatedUser.locale = this.userLanguage;
    this.updatedUser.secondaryEmails = [...userSecondaryEmails];

    this.updatedUserClinics = this.getClinicsWithValidPhones(userClinics);

    this.saveUserState();
  },
  mounted() {
    if (isMobileDevice()) {
      return;
    }

    this.scrollbar = new PerfectScrollbar(
      this.$refs.doctorEditSection,
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    );
  },
  async beforeRouteLeave(to, from, next) {
    const updatedUserState = this.getUpdatedUserState();
    const userAnswer = await this.onPageClose(updatedUserState, this.savedUserState);

    if (!userAnswer) {
      next(false);

      return;
    }

    if (userAnswer !== UNSAVED_CHANGES_INPUTS.YES) {
      next();

      return;
    }

    if ((await this.$validator.validateAll()) && !this.hasValidationError) {
      next();
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setLocale: rootTypes.actions.SET_LOCALE,
      getUpdatedUser: rootTypes.actions.UPDATE_USER,
      updateUserPhoto: rootTypes.actions.UPDATE_USER_PHOTO
    }),
    async updatePhoto() {
      this.setLoading(true);
      await this.updateUserPhoto(this.userId);
      this.setLoading(false);
    },
    clearSlugErrors() {
      if (this.doctorSlugInvalid) {
        this.errors.remove('doctorSlug');
        this.clearError();
      }
    },
    clearNameErrors() {
      if (this.isDoctorNameHasErrors) {
        this.errors.remove('doctorName');
      }
    },
    clearError() {
      this.slugInputError = '';
      this.websiteInputError = '';
    },
    saveUserState() {
      this.savedUserState = cloneDeep(this.getUpdatedUserState());
    },
    getUpdatedUserState() {
      return { ...this.updatedUser, clinics: this.updatedUserClinics };
    },
    toggleDoctorSlugChanging() {
      this.isDoctorSlugEditable = !this.isDoctorSlugEditable;
    },
    addSecondaryEmail(email) {
      this.updatedUser.secondaryEmails.push(email);
    },
    deleteSecondaryEmail(emailIndex) {
      this.updatedUser.secondaryEmails.splice(emailIndex, 1);
    },
    updateUserField(fieldName, value) {
      this.updatedUser[fieldName] = value;
    },
    updateLocale(locale) {
      this.updatedUser.locale = locale;
    },
    async checkBeforeModalClose() {
      const updatedUserState = this.getUpdatedUserState();
      const userAnswer = await this.onPageClose(updatedUserState, this.savedUserState);

      if (!userAnswer) {
        return;
      }

      if (userAnswer !== UNSAVED_CHANGES_INPUTS.YES) {
        this.closeModal();

        return;
      }

      if ((await this.$validator.validateAll()) && !this.hasValidationError) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$modal.hide('edit-doctor-info-modal');
    },
    async saveChanges() {
      if (await this.$validator.validateAll()) {
        const isPhoneValid = validateDoctorPhone(this.updatedUser);

        this.slugInputError = validateDoctorSlug(this.$t, this.updatedUser);
        this.websiteInputError = validateWebsite(this.$t, this.updatedUser);

        if (this.slugInputError || this.websiteInputError || !isPhoneValid) {
          return;
        }

        this.updatedUser.secondaryEmails = this.updatedUser.secondaryEmails.filter(
          email => !isEmpty(email)
        );

        this.updatedUser.personalPage = getUrlWithProtocol(this.updatedUser.personalPage);

        this.setLoading(true);

        const { status } = await updateUser(this.updatedUser);

        if (status === REQUEST_ERRORS.BAD_REQUEST) {
          this.slugInputError = this.$t('dashboard.warning.usedEmail');
          this.setLoading(false);

          return;
        }

        await updateUserClinics(this.updatedUserClinics);
        await updateDoctorSignature({ doctorId: this.userId, doctorName: this.updatedUser.name });

        this.updateDashboardLocale();
        await this.getUpdatedUser(this.userId);
        this.setLoading(false);

        this.saveUserState();

        if (isMobileDevice()) {
          this.returnToProfile();

          return;
        }

        this.closeModal();
      }

      if (this.doctorSlugInvalid) {
        this.isDoctorSlugEditable = true;
      }
    },
    updateDashboardLocale() {
      const { locale } = this.updatedUser;
      const dashboardLocale = getDashboardLocale(locale);

      this.setLocale(dashboardLocale);
    },
    returnToProfile() {
      this.$router.push({ name: 'UserProfile' });
    },
    cancelChangesAndReturnToProfile() {
      this.savedUserState = cloneDeep(this.getUpdatedUserState());
      this.returnToProfile();
    },
    getClinicsWithValidPhones(clinics) {
      return [...clinics].map(clinic => {
        const { clinicAddress: address } = clinic;
        const clinicAddress = {
          ...address,
          phone: address.phone || '',
          phoneIso: address.phoneIso || this.defaultClinicIsoCode
        };

        return { ...clinic, clinicAddress };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/css/common/photos.css';
@import '../../../../assets/css/common/inputs.css';
@import '../../../../assets/css/common/errors';
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/css/common/perfect-scrollbar-styles.css';

@import '../../../../assets/scss/profile/edit-doctor-shared';

.header__dropdown-menu {
  padding: 0;
  margin: 0;
}

.rounded-input {
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;
  padding: 9px 10px;
  width: auto;
}

.save-changes-button {
  font-size: 15px;
  line-height: 17px;
  padding: 9px 15px;
  margin-left: 20px;
}

.doctor-info {
  width: 100%;

  &__edit-section {
    max-height: 555px;
    position: relative;

    padding: 40px 55px 4px 40px;
    display: flex;
  }

  &__header {
    height: 50px;
    padding: 0 20px;
  }
}

.text-information-edit {
  &__tag-selector {
    width: 500px;
  }
}

.fa-trash-alt {
  color: $icon-grey-color;
  margin-left: 10px;
  cursor: pointer;
}

.photo__container {
  margin-bottom: 20px;
  width: 200px;
  height: 200px;
}

.change-photo {
  margin-right: 40px;
}

.icon-orange-pencil {
  padding: 10px;
  background-size: 13px;
}

@media (max-width: 900px) {
  .photo__container {
    width: 150px;
    height: 150px;
  }

  .change-photo {
    margin-right: 20px;
  }
}

.text-information-edit {
  flex-grow: 1;
}

.doctor-urls-editor,
.doctor-name__container {
  display: flex;
  flex-direction: column;
}

.doctor-slug-url {
  display: flex;
  align-items: baseline;
}

.doctor-slug-input {
  flex-grow: 1;
}

.doctor-slug__container {
  flex-grow: 1;
  margin-top: 8px;
  margin-bottom: 20px;
}

.details-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}

.text-information-edit__details {
  display: flex;
}

.language-selector,
.doctor-personal-page,
.details__location,
.details__emails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;

  &:not(:last-of-type) {
    margin-right: 20px;
  }
}

.doctor-personal-page {
  &__input {
    padding-top: 8px;
  }
}

.secondary-email {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__input {
    flex-grow: 1;
  }
}

.add-secondary-email-title {
  color: $orange-color;
  cursor: pointer;
}

.doctor-info__footer {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 19px 45px;
}

.doctor-slug-input {
  display: flex;
  flex-direction: column;
}

.tag-icon {
  color: $icon-grey-color;
}

.inactive {
  color: $light-grey-color;
  cursor: default;
}

@media screen and (max-width: 767px) {
  .doctor-info {
    background-color: $white;
    font-size: 12px;
    line-height: 150%;

    &__edit-section {
      max-height: none;
      padding: 86px 20px 10px;
    }

    &__input {
      font-size: 16px;
      line-height: 19px;
    }

    &__footer {
      justify-content: center;
    }

    &__header {
      position: fixed;
      background-color: $dim-white;
      justify-content: flex-start;
      z-index: 999;
    }
  }

  .location {
    &__phone-field {
      margin-bottom: -5px;
    }
  }

  .photo__container {
    width: 100px;
    height: 100px;
  }

  .change-photo {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .text-information-edit {
    &__tag-selector {
      width: 100%;
    }
  }

  .language-and-personal-page,
  .doctor-info__edit-section,
  .text-information-edit__details {
    flex-direction: column;
  }

  .language-selector,
  .doctor-personal-page,
  .details__location,
  .details__emails {
    flex-basis: initial;
    margin-right: 0;

    &:not(:last-of-type) {
      margin-right: 0;
    }
  }

  .language-selector {
    margin-bottom: 10px;
  }

  .doctor-slug-input {
    min-width: 115px;
  }

  .rounded-input {
    padding: 8px 10px;
    margin-bottom: 10px;
  }

  .details-divider {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .header__title {
    margin-left: 10px;
    color: $main-title-color;
  }

  .change-photo__button,
  .header__title,
  .doctor-slug-url,
  .doctor-slug__container {
    font-size: 16px;
    line-height: 19px;
  }

  .cancel-button,
  .save-changes-button {
    font-size: 16px;
    line-height: 19px;
  }

  .save-changes-button {
    padding: 7px 15px;
  }
}

.invisible {
  display: none;
}
</style>
