<template>
  <div>
    <div class="notification">
      <span
        class="fas notification__icon custom-icon"
        aria-hidden="true"
        :class="notificationClass"
      ></span>
      <div v-if="displayText" class="notification__text">
        <span>{{ notificationText }}</span>
        <i v-if="isOngoingTreatment" class="notification__treatment-count">
          &bull; {{ treatmentCount }} {{ $t('dashboard.counter.days') }}
        </i>
        <i v-if="isNewTreatment" class="notification__days-to-start">
          {{ treatmentStartDaysCounter }}
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import { getDaysTranslationWithNumber } from '@/modules/dashboard/utils/date-utils';

import { NOTIFICATION_TYPE } from '../../api/constants';

export default {
  name: 'NotificationSubject',
  props: {
    subject: {
      type: String,
      required: true
    },
    displayText: {
      type: Boolean,
      default: false
    },
    patientChrono: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    notificationClass() {
      return {
        'icon-camera': this.isNewPhotoAnalysis,
        'icon-clipboard': this.isNewQuestionnaire,
        'fa-flag-checkered icon-treatment-competed': this.isExpiredFormula,
        'fa-hourglass-end icon-ongoing-treatment': this.isOngoingTreatment,
        'icon-inactive': this.isInactive,
        'icon-clocks': this.isNewTreatment,
        'icon-offer-sent': this.isOfferCreated,
        'icon-under-preparation': this.isUnderPreparation,
        'icon-order-cancelled': this.isCancelled
      };
    },
    notificationText() {
      if (this.isNewQuestionnaire) {
        return this.$i18n.t('dashboard.status.newQuestionnaireFilled');
      }

      if (this.isNewPhotoAnalysis) {
        return this.$i18n.t('dashboard.status.newPhotoAnalysis');
      }

      if (this.isExpiredFormula) {
        return this.$i18n.t('dashboard.status.treatmentCompleted');
      }

      if (this.isOngoingTreatment) {
        return this.$i18n.t('dashboard.status.ongoingTreatment');
      }

      if (this.isInactive) {
        return this.$i18n.t('dashboard.status.inactive');
      }

      if (this.isNewTreatment) {
        return this.hasNewTreatmentTranslationWithNumber
          ? this.$t('dashboard.label.treatmentStartsIn')
          : this.$t('dashboard.counter.startsTomorrow');
      }

      if (this.isOfferCreated) {
        return this.$t('dashboard.patientStatus.offerSent');
      }

      if (this.isUnderPreparation) {
        return this.$t('dashboard.status.underPreparation');
      }

      if (this.isCancelled) {
        return this.$t('dashboard.status.orderCancelled');
      }

      return '';
    },
    isNewQuestionnaire() {
      return this.subject === NOTIFICATION_TYPE.NEW_QUESTIONNAIRE;
    },
    isNewPhotoAnalysis() {
      return this.subject === NOTIFICATION_TYPE.NEW_PHOTO_ANALYSIS;
    },
    isExpiredFormula() {
      return this.subject === NOTIFICATION_TYPE.TREATMENT_COMPLETED;
    },
    isOngoingTreatment() {
      return this.subject === NOTIFICATION_TYPE.ONGOING_TREATMENT;
    },
    isInactive() {
      return this.subject === NOTIFICATION_TYPE.INACTIVE;
    },
    isNewTreatment() {
      return this.subject === NOTIFICATION_TYPE.TREATMENT_STARTS_IN;
    },
    isOfferCreated() {
      return this.subject === NOTIFICATION_TYPE.OFFER_SENT;
    },
    isUnderPreparation() {
      return this.subject === NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY;
    },
    isCancelled() {
      return this.subject === NOTIFICATION_TYPE.ORDER_CANCELLED;
    },
    treatmentCount() {
      const {
        numberOfDaysForTreatmentFromBeginning,
        totalNumberOfDaysForTreatment
      } = this.patientChrono;

      return `${numberOfDaysForTreatmentFromBeginning}/${totalNumberOfDaysForTreatment}`;
    },
    treatmentStartDaysCounter() {
      return this.hasNewTreatmentTranslationWithNumber
        ? getDaysTranslationWithNumber(this.$t, this.startDays)
        : '';
    },
    startDays() {
      return this.patientChrono.numberOfDaysBeforeTreatmentStart || 1;
    },
    hasNewTreatmentTranslationWithNumber() {
      return this.startDays > 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/dashboard/notifications';
@import '../../../../assets/scss/helpers/colors';

.notification {
  display: flex;
  align-items: center;

  &__icon {
    width: 16px;
    height: 20px;

    line-height: 20px;

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__text {
    margin-left: 8px;

    overflow: visible;
    white-space: nowrap;

    font-size: 13px;
    line-height: 15px;

    color: $black-color-text;
  }

  &__ongoing-treatment-label {
    color: $marengo-color;
  }

  &__treatment-count {
    margin-top: 2px;

    font-size: 12px;
  }

  &__treatment-count,
  &__days-to-start {
    color: $grey-color;
  }
}

@media screen and (max-width: 767px) {
  .notification {
    &__icon {
      width: 14px;
      height: 16px;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .filter__container {
    .notification__icon {
      margin-bottom: 0;
    }
  }

  .patient-record {
    .notification__icon {
      margin: 0;
    }
  }
}
</style>
