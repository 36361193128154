<template>
  <modal
    name="two-factor-authentication-set-up-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    :click-to-close="false"
    width="800px"
    height="680px"
    @before-open="onBeforeOpen"
  >
    <div class="modal-container">
      <two-factor-authentication-set-up :is-header-with-back-button="isHeaderWithBackButton" />
    </div>
  </modal>
</template>

<script>
import TwoFactorAuthenticationSetUp from './TwoFactorAuthenticationSetUp';

export default {
  name: 'TwoFactorAuthenticationSetUpModal',
  components: { TwoFactorAuthenticationSetUp },
  data() {
    return {
      isHeaderWithBackButton: false
    };
  },
  methods: {
    onBeforeOpen({ params: { isHeaderWithBackButton = false } }) {
      this.isHeaderWithBackButton = isHeaderWithBackButton;
    }
  }
};
</script>
