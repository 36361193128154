<template>
  <modal
    name="notification-list-modal"
    :classes="['modal-window']"
    transition="scale"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <notification-list @updateBell="updateBell"></notification-list>
      </div>
    </div>
  </modal>
</template>

<script>
import NotificationList from '@/modules/dashboard/components/notifications/NotificationList';

export default {
  name: 'NotificationListModal',
  components: { NotificationList },
  methods: {
    updateBell() {
      this.$emit('updateBell');
    }
  }
};
</script>

<style scoped>
.modal-container {
  margin: 0;
}
</style>
