<template>
  <modal
    name="edit-doctor-info-modal"
    :classes="['modal-window']"
    transition="scale"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    :click-to-close="false"
    width="835"
    height="auto"
  >
    <div class="modal-container">
      <edit-doctor-info></edit-doctor-info>
    </div>
  </modal>
</template>

<script>
import EditDoctorInfo from '@/modules/dashboard/components/profile/EditDoctorInfo';

export default {
  name: 'EditDoctorInfoModal',
  components: { EditDoctorInfo }
};
</script>
