<template>
  <div class="edit-clinics">
    <clinic-phone-selector
      v-for="(clinic, index) in value"
      :key="clinic.id"
      :value="clinic"
      @input="updateClinic($event, index)"
    />
  </div>
</template>

<script>
import ClinicPhoneSelector from '@/modules/dashboard/components/profile/ClinicPhoneSelector';

export default {
  name: 'EditClinics',
  components: { ClinicPhoneSelector },
  inject: ['$validator'],
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updateClinic(clinic, index) {
      const clinics = [...this.value];

      clinics.splice(index, 1, clinic);

      this.$emit('input', clinics);
    }
  }
};
</script>

<style lang="scss" scoped></style>
