<template>
  <div class="p0 text-center">
    <div ref="dropdown" class="more dropdown">
      <div class="dropdown__button-wrapper" @click="toggleDropdown">
        <slot name="button"></slot>
      </div>
      <ul class="dropdown-menu dropdown-menu-right burger-dropdown" :class="dropdownClass">
        <slot name="list-elements"></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { legacyDropdownMixin } from '@/modules/dashboard/mixins/dropdown/legacyDropdownMixin';

export default {
  name: 'Dropdown',
  mixins: [legacyDropdownMixin],
  props: {
    hasNoPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dropdownClass() {
      return {
        'dropdown-menu--padding-none': this.hasNoPadding,
        'dropdown-menu--opened': this.isDropdownOpened
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.dropdown-menu.dropdown-menu {
  &--padding-none {
    padding: 0;
  }

  &--opened {
    display: block;
  }
}

.burger-dropdown.dropdown-menu--padding-none {
  padding: 0;
}

.burger-dropdown {
  padding: 20px 20px 0;
  right: -10px;

  box-shadow: 0 6px 10px rgba(148, 166, 176, 0.3);
  border-radius: 8px;
}

.burger-dropdown-option {
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 20px;

  color: $text-color;

  cursor: pointer;
}

.burger-dropdown > li {
  padding: 0;
}

@media (max-width: 767px) {
  .header__dropdown-menu {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
  }

  .burger-dropdown {
    margin-top: 5px;
  }
}
</style>
