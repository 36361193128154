<template>
  <div>
    <div class="tabs__container">
      <div class="tabs" :class="tabsAlignClass">
        <div
          v-for="tab in tabs"
          :key="tab.name"
          class="tab"
          :class="{ 'tab--active': tab.name === activeTab }"
          @click="selectTab(tab.name)"
        >
          <template v-if="!customTabs">
            <span class="no-search-field">{{ isLabelsAreKeys ? $t(tab.label) : tab.label }}</span>
          </template>
          <template v-else>
            <slot name="custom-tabs" v-bind="tab"></slot>
          </template>
        </div>
      </div>
    </div>
    <slot name="tab-content"></slot>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      }
    },
    activeTab: {
      type: String,
      required: true
    },
    customTabs: {
      type: Boolean,
      default: false
    },
    isLabelsAreKeys: {
      type: Boolean,
      default: false
    },
    tabsAlign: {
      type: String,
      default: ''
    }
  },
  computed: {
    tabsAlignClass() {
      return {
        'tabs--centered': this.tabsAlign === 'center',
        'tabs--flex-start': this.tabsAlign === 'flex-start'
      };
    }
  },
  methods: {
    selectTab(tabName) {
      this.$emit('select-tab', tabName);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.tabs {
  width: 90%;

  display: flex;
  justify-content: space-between;

  margin-bottom: -1px;

  &--centered {
    justify-content: center;
  }

  &--flex-start {
    justify-content: flex-start;
  }

  &__container {
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;

    margin-top: 20px;
    margin-bottom: 25px;

    border-bottom: 1px solid $separator-grey-color;
  }
}

.tab {
  height: 100%;

  display: flex;
  align-items: center;

  margin: 0 15px;

  text-align: center;

  font-size: 15px;
  color: $cancel-text-color;

  border-bottom: 1px solid transparent;

  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &--active {
    color: $active-title-color;
    font-weight: bold;
    border-bottom: 1px solid $orange-color;

    cursor: default;
  }
}

@media (max-width: 767px) {
  .tab {
    flex-grow: 1;
    justify-content: center;

    font-size: 12px;

    margin: 0;
  }
}
</style>
