<template>
  <div class="header">
    <div
      v-show="!isSearchOpened"
      key="header"
      class="flex--space-between--center header__container"
    >
      <router-link :to="{ name: 'Dashboard' }" active-class="noSelectable">
        <div class="header__logo pointer" @click="scrollToTheTop"></div>
      </router-link>
      <div class="flex--space-between--center header__button-group">
        <span
          class="header__search-button icon-search"
          :class="{ 'header__search-button--invisible': !displaySearchIcon }"
          aria-hidden="true"
          @click="toggleSearchField"
        ></span>

        <bell-notifications class="header__bell-notifications" />

        <div class="header__profile-button flex--align-center">
          <profile-button />
          <dropdown class="header__dropdown">
            <template slot="button">
              <span
                class="fas fa-bars header__dropdown-menu-arrow pointer"
                data-toggle="dropdown"
              ></span>
            </template>
            <template slot="list-elements">
              <li>
                <a class="burger-dropdown-option" @click="showSettings">
                  {{ $t('dashboard.button.priceSettings') }}
                </a>
              </li>
              <li>
                <router-link class="burger-dropdown-option" :to="{ name: 'MessageTemplates' }">
                  {{ $t('dashboard.action.messageTemplates') }}
                </router-link>
              </li>
              <li>
                <router-link class="burger-dropdown-option" :to="{ name: 'UserGuideMainPage' }">
                  {{ $t('dashboard.label.userGuide') }}
                </router-link>
              </li>
              <li>
                <router-link class="burger-dropdown-option" :to="{ name: 'LoyaltyProgram' }">
                  {{ loyaltyProgramOptionLabel }}
                </router-link>
              </li>
              <li>
                <router-link class="burger-dropdown-option" :to="{ name: 'UserProfile' }">
                  {{ $t('dashboard.action.myAccount') }}
                </router-link>
              </li>
              <li v-if="hasOnlineConsultationAccess">
                <router-link class="burger-dropdown-option" :to="{ name: 'OnlineConsultationFee' }">
                  {{ $t('dashboard.action.onlineConsultationFee') }}
                </router-link>
              </li>
              <li>
                <a class="burger-dropdown-option log-out-option" @click="onLogout">
                  <div class="icon-log-out custom-icon"></div>
                  <span class="log-out-option__text">
                    {{ $t('dashboard.action.signOut') }}
                  </span>
                </a>
              </li>
            </template>
          </dropdown>
        </div>
      </div>
    </div>
    <template v-if="displaySearchIcon">
      <transition name="search-slide">
        <search-field
          v-show="isSearchOpened"
          ref="searchField"
          :is-header-search="true"
          @cross-icon-click="onCrossIconClick"
        />
      </transition>
    </template>

    <product-price-modal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BellNotifications from '@/modules/dashboard/components/home/BellNotifications';
import ProfileButton from '@/modules/dashboard/components/home/ProfileButton';
import Dropdown from '@/modules/dashboard/components/common/Dropdown';
import SearchField from '@/modules/dashboard/components/common/SearchField';
import ProductPriceModal from '@/modules/dashboard/components/price-settings/ProductPricesModal';

import { logoutMixin } from '@/modules/dashboard/mixins/logoutMixin';

import { COUNTRIES, FRANCE_REGION_COUNTRY_CODES } from '@/api/places/COUNTRIES';
import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

export default {
  name: 'Header',
  components: {
    ProductPriceModal,
    BellNotifications,
    ProfileButton,
    Dropdown,
    SearchField
  },
  mixins: [logoutMixin],
  props: {
    displaySearchIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isSearchOpened: false
    };
  },
  computed: {
    ...mapGetters({
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS,
      doctorCountry: rootTypes.getters.GET_USER_COUNTRY
    }),
    hasOnlineConsultationAccess() {
      if (!this.doctorCountry) {
        return false;
      }

      const forbiddenCountry = COUNTRIES.mx.alpha2Code.toLowerCase();
      const doctorCountry = this.doctorCountry.toLowerCase();

      return this.hasWebshopAccess && doctorCountry !== forbiddenCountry;
    },
    loyaltyProgramOptionLabel() {
      if (!this.doctorCountry) {
        return '';
      }

      const isRegionFrance = FRANCE_REGION_COUNTRY_CODES.includes(this.doctorCountry.toLowerCase());

      return isRegionFrance
        ? this.$t('dashboard.action.loyaltyProgramFrance')
        : this.$t('dashboard.action.loyaltyProgram');
    }
  },
  methods: {
    ...mapActions({
      updateFilter: types.actions.UPDATE_FILTER
    }),
    async toggleSearchField() {
      this.isSearchOpened = !this.isSearchOpened;

      if (this.isSearchOpened) {
        await this.$nextTick();

        this.$refs.searchField.focusInput();
      }
    },
    onCrossIconClick() {
      this.updateFilter('');
      this.toggleSearchField();
    },
    showSettings() {
      this.$modal.show('price-settings-modal');
    },
    scrollToTheTop() {
      window.scrollTo(0, 0);
    },
    async onLogout() {
      await this.logoutAndClearModule();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';

.header {
  height: 80px;
  width: 100%;

  display: flex;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background-color: $dim-white;

  z-index: 20;

  &__container {
    width: 980px;
    height: 100%;
  }

  &__logo {
    width: 113px;
    height: 45px;
    background-image: url('~@/assets/images/dashboard/icons/skinxs-logo-medium.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__dropdown {
    padding: 0 20px 0 0;
  }

  &__button-group {
    max-width: 70%;
    color: $text-color;
  }

  &__search-button {
    display: none;
  }

  &__dropdown-menu-arrow {
    margin-left: 27px;

    font-size: 15px;
  }

  &__profile-button {
    margin-left: 30px;
  }
}

.search-slide-enter-active,
.search-slide-leave-active {
  transition: transform 0.2s;
}

.search-slide-enter,
.search-slide-leave-to {
  position: absolute;
  transform: translateY(-50px);
}

.search-slide-enter-to,
.search-slide-leave {
  position: absolute;
}

.burger-dropdown-option {
  padding: 0;
  display: block;
  white-space: nowrap;
}

.log-out-option {
  display: flex;
  margin-top: -4px;

  &__text {
    padding-top: 4px;
  }
}

.icon-log-out {
  margin-right: 8px;
}

.header-text::-ms-clear {
  display: none;
}

@media (max-width: 575px) {
  .header {
    input.header-text {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 768px) {
  .containerAlt {
    max-width: 600px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header__container {
    width: 100%;

    padding: 15px;
  }
}

@media (max-width: 767px) {
  .header {
    height: 60px;

    &__container {
      width: 100%;

      padding: 15px;
    }

    &__logo {
      width: 31px;
      height: 31px;
      background-image: url('~@/assets/images/dashboard/icons/skinxs-logo-small.svg');
    }

    &__bell-notifications {
      margin: 0 18px;
    }

    &__search-button {
      display: block;
      margin-top: 3px;
      width: 18px;
      height: 18px;

      &--invisible {
        display: none;
      }
    }

    &__dropdown-menu-arrow {
      display: inline-block;
      font-size: 21px;
      margin-left: 0;
      margin-top: 7px;
    }

    &__profile-button {
      margin-left: 0;
    }
  }
}
</style>
