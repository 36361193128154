<template>
  <div class="root-container">
    <Header :display-search-icon="displaySearchIcon" />
    <TemplateContent class="template-content-container">
      <slot />
    </TemplateContent>
  </div>
</template>

<script>
import Header from '@/modules/dashboard/components/home/Header';
import TemplateContent from '@/modules/dashboard/components/templates/TemplateContent';

export default {
  name: 'TemplateWithHeader',
  components: { Header, TemplateContent },
  props: {
    displaySearchIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.root-container {
  display: flex;
  justify-content: center;
}

.template-content-container {
  padding-top: 80px;
}

@media (max-width: 767px) {
  .template-content-container {
    padding-top: 60px;
  }
}
</style>
