<template>
  <modal
    name="price-settings-modal"
    class="content-box-sizing"
    :classes="['modal-window']"
    transition="scale"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    :click-to-close="false"
    :width="width"
    :height="height"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <product-price-list />
      </div>
    </div>
  </modal>
</template>

<script>
import ProductPriceList from './ProductPriceList';

const DESKTOP_SIZES = {
  WIDTH: '700',
  HEIGHT: '550'
};

const MOBILE_SIZES = {
  WIDTH: '300',
  HEIGHT: '445'
};

export default {
  name: 'ProductPricesModal',
  components: { ProductPriceList },
  data() {
    return {
      width: DESKTOP_SIZES.WIDTH,
      height: DESKTOP_SIZES.HEIGHT
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 768) {
        this.width = MOBILE_SIZES.WIDTH;
        this.height = MOBILE_SIZES.HEIGHT;
      } else {
        this.width = DESKTOP_SIZES.WIDTH;
        this.height = DESKTOP_SIZES.HEIGHT;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  margin: 0;

  z-index: 1000;
  overflow: hidden;
}

.modal-window-content {
  height: 100%;
}
</style>
