<template>
  <div>
    <two-factor-authentication-set-up-modal />
    <authentication-recovery-codes-modal />
    <authentication-scan-code-modal />
    <authentication-manual-flow-modal />
  </div>
</template>

<script>
import TwoFactorAuthenticationSetUpModal from '@/modules/dashboard/components/two-factor-authentication/TwoFactorAuthenticationSetUpModal';
import AuthenticationRecoveryCodesModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationRecoveryCodesModal';
import AuthenticationScanCodeModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationScanCodeModal';
import AuthenticationManualFlowModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationManualFlowModal';

export default {
  name: 'TwoFactorAuthenticationModals',
  components: {
    TwoFactorAuthenticationSetUpModal,
    AuthenticationRecoveryCodesModal,
    AuthenticationScanCodeModal,
    AuthenticationManualFlowModal
  }
};
</script>
