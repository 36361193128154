<template>
  <div class="content-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TemplateContent'
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/templates/content-container';
</style>
