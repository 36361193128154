<template>
  <div class="notifications">
    <div class="header">
      <span class="header__title">{{ $t('dashboard.label.currentNotification') }}</span>
      <button
        class="small-button-white otherXS"
        :disabled="isAllNotificationRead"
        @click.stop="markAllAsRead"
      >
        {{ $t('dashboard.action.markAll') }}
      </button>
      <div class="onlyXS">
        <div class="header__mobile-buttons">
          <div
            class="vertical-dots"
            tabindex="-1"
            @click="toggleMarkAllAsReadDropdown"
            @blur="disableMarkAllAsReadDropdown"
          ></div>
          <div
            class="main-dropdown dropdown-menu dropdown-menu-right"
            :class="{ visible: markAllAsReadDropdownVisible }"
            @mousedown="markAllAsRead"
          >
            <div class="dropdown-item main-dropdown__option">
              {{ $t('dashboard.action.markAll') }}
            </div>
          </div>
          <div class="icon-close custom-icon" @click="closeModalNotificationList"></div>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="notification-list" infinite-wrapper>
      <notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        @mark-notification="markNotification(notification, $event)"
      />
      <infinite-loading
        ref="notificationLoader"
        spinner="waveDots"
        :force-use-infinite-wrapper="true"
        @infinite="infiniteHandler"
      >
        <div slot="no-results" class="notifications-loader-slot no-result-slot">
          <div class="notifications-loader-slot__text flex--align-center">
            {{ $t('dashboard.info.noRecentNotifications') }}
          </div>
        </div>
        <div slot="no-more" class="notifications-loader-slot">
          <div class="notifications-loader-slot__text flex--align-center">
            {{ $t('dashboard.info.notificationsRemoved') }}
          </div>
        </div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

import InfiniteLoading from 'vue-infinite-loading';
import PerfectScrollbar from 'perfect-scrollbar';

import Notification from '@/modules/dashboard/components/notifications/Notification';

import { fetchNotifications, markAllNotificationAsRead } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';
import { NOTIFICATIONS_DOWNLOADING_SIZE } from '@/constants';

export default {
  name: 'NotificationList',
  components: {
    Notification,
    InfiniteLoading
  },
  data() {
    return {
      notifications: [],
      currentPage: 0,
      shouldIncreasePageNumber: true,
      notificationListScrollbar: null,
      markAllAsReadDropdownVisible: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    isAllNotificationRead() {
      return this.notifications.every(({ isRead }) => isRead);
    }
  },
  async mounted() {
    const notificationList = document.body.querySelectorAll('.notification-list');

    this.notificationListScrollbar = new PerfectScrollbar(
      notificationList[notificationList.length - 1],
      {
        suppressScrollX: true,
        wheelPropagation: false
      }
    );

    await this.activateInfiniteHandler(this.$refs.notificationLoader.stateChanger);
  },
  beforeDestroy() {
    this.$refs.notificationLoader.stateChanger.complete();
  },
  methods: {
    infiniteHandler: debounce(
      function infiniteHandlerCallback($state) {
        this.activateInfiniteHandler($state, this.shouldIncreasePageNumber);
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),
    async activateInfiniteHandler($state, shouldIncreasePageNumber) {
      if (shouldIncreasePageNumber) {
        this.currentPage += 1;
      }

      const { data: notifications } = await fetchNotifications({
        doctorId: this.userId,
        page: this.currentPage,
        size: NOTIFICATIONS_DOWNLOADING_SIZE
      });

      if (notifications.length) {
        this.notifications = this.notifications.concat(notifications);

        if (notifications.length < 1) {
          $state.complete();

          return;
        }

        $state.loaded();

        if (notifications.length < 10) {
          $state.complete();
        }

        this.notificationListScrollbar.update();
      } else {
        $state.complete();
      }
    },
    async markAllAsRead() {
      await markAllNotificationAsRead({ doctorId: this.userId });
      this.resetNotifications();
    },
    toggleMarkAllAsReadDropdown() {
      this.markAllAsReadDropdownVisible = !this.markAllAsReadDropdownVisible;
    },
    disableMarkAllAsReadDropdown() {
      this.markAllAsReadDropdownVisible = false;
    },
    closeModalNotificationList() {
      this.$modal.hide('notification-list-modal');
    },
    markNotification(notification, { isRead }) {
      notification.isRead = isRead;

      this.updateBell();
    },
    updateBell() {
      this.$emit('updateBell');
    },
    resetNotifications() {
      this.notifications = [];
      this.currentPage = 0;
      this.updateBell();
      this.$nextTick(() => this.$refs.notificationLoader.stateChanger.reset());
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons.scss';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../assets/css/common/perfect-scrollbar-styles.css';
@import '../../../../assets/css/common/dropdowns.css';
@import '../../../../assets/css/common/icons.css';

.notifications {
  background-color: $white;
  border-radius: 8px;
  position: relative;
}

.notification-list {
  position: relative;
  overflow: hidden;
  max-height: 460px;
  min-height: 250px;
  margin-top: 15px;
}

.header {
  width: 100%;
  height: 50px;
  padding: 0 10px 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    color: $active-title-color;
  }
}

.header__mobile-buttons {
  display: flex;
  align-items: center;
}

.separator {
  border-top: 1px solid $dim-white;
}

.notifications-loader-slot {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    height: 40px;
    padding: 10px 20px;

    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    text-align: center;

    color: $cancel-text-color;

    background: $dim-blue;
    border-radius: 8px;
  }
}

.no-result-slot {
  height: 250px;
}

.main-dropdown {
  top: 11%;
  right: 60px;
  min-width: 130px;

  &__option {
    z-index: 1000;
  }
}

.icon-close {
  margin-right: 12px;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .notifications {
    overflow: hidden;
    box-shadow: 0 6px 10px rgba(148, 166, 176, 0.3);
  }

  .notification-list {
    max-height: 310px;
    padding-top: 5px;
  }

  .header {
    padding: 17px 20px;

    &__title {
      font-size: 13px;
      line-height: 15px;
    }

    .icon-close {
      margin-right: -10px;
      margin-left: 5px;
      padding: 10px;
      background-size: 12px;
    }
  }
}
</style>
