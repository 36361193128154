<template>
  <modal
    name="photo-validation-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    :click-to-close="false"
    width="280px"
    height="auto"
    @before-open="beforeOpen"
  >
    <section class="photo-validation modal-container flex-column--align-center">
      <span class="modal-window-text">{{ errorMessage }}</span>
      <button
        v-if="hasUploadAnotherButton"
        class="photo-validation__try-again rounded-button-white"
        @click="tryAgain"
      >
        {{ $t('dashboard.warning.action.addAnotherPhoto') }}
      </button>
      <a class="cancel-button" :class="{ mt20: !hasUploadAnotherButton }" @click="closeModal">
        {{ $t('dashboard.action.cancel') }}
      </a>
    </section>
  </modal>
</template>

<script>
import { PHOTO_VALIDATION_ERRORS } from '@/modules/dashboard/api/constants';

import { MAX_PHOTO_SIZE_IN_MB } from '@/modules/questionnaire/api/constants';

export default {
  name: 'PhotoValidationModal',
  data() {
    return {
      validationError: '',
      maxSizeExceeds: MAX_PHOTO_SIZE_IN_MB,
      hasUploadAnotherButton: true
    };
  },
  computed: {
    errorMessage() {
      let translationKey;

      switch (this.validationError) {
        case PHOTO_VALIDATION_ERRORS.MAX_FILE_SIZE: {
          translationKey = 'sizeExceeds';
          return this.$t(`dashboard.warning.${translationKey}`, [this.maxSizeExceeds]);
        }
        case PHOTO_VALIDATION_ERRORS.TYPE_NOT_ALLOWED: {
          translationKey = 'fileNotImage';
          break;
        }
        case PHOTO_VALIDATION_ERRORS.NO_FACE_ON_PHOTO: {
          translationKey = 'couldNotDetectFace';
          break;
        }
        case PHOTO_VALIDATION_ERRORS.SEVERAL_FACES_ON_PHOTO: {
          translationKey = 'seemMultipleFaces';
          break;
        }
        default: {
          return 'Photo validation error. Please, try again';
        }
      }

      return this.$t(`dashboard.warning.${translationKey}`);
    }
  },
  methods: {
    beforeOpen({
      params: {
        validationError,
        maxSizeExceeds = MAX_PHOTO_SIZE_IN_MB,
        hasUploadAnotherButton = true
      }
    }) {
      this.validationError = validationError;
      this.maxSizeExceeds = maxSizeExceeds;
      this.hasUploadAnotherButton = hasUploadAnotherButton;
    },
    tryAgain() {
      this.$emit('try-again');
      this.hideThisModal();
    },
    closeModal() {
      this.$emit('close');
      this.hideThisModal();
    },
    hideThisModal() {
      this.$modal.hide('photo-validation-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/common/text';

.photo-validation {
  padding: 30px 20px;

  &__try-again {
    margin: 30px 0 20px 0;
  }
}
</style>
