<template>
  <modal
    name="authentication-manual-flow-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    :click-to-close="false"
    width="800px"
    height="680px"
  >
    <div class="modal-container">
      <two-factor-authentication-manual-flow />
    </div>
  </modal>
</template>

<script>
import TwoFactorAuthenticationManualFlow from '@/modules/dashboard/components/two-factor-authentication/TwoFactorAuthenticationManualFlow';

export default {
  name: 'AuthenticationManualFlowModal',
  components: { TwoFactorAuthenticationManualFlow }
};
</script>
