<template>
  <section class="personal-info-container">
    <div class="personal-info-content">
      <div class="doctor-photo-information">
        <div class="photo__container" :style="userPhotoStyles"></div>
        <div class="text-information__header flex--space-between--center onlyXS">
          <div class="header__user-name main-title">{{ user.name }}</div>
          <div class="header__edit-button rounded-button-gray" @click="openEditPage">
            <div class="edit-button__icon icon-pencil custom-icon"></div>
            <span class="edit-button__text">
              {{ $t('dashboard.button.edit') }}
            </span>
          </div>
        </div>
      </div>
      <div class="text-information">
        <div class="text-information__header flex--space-between--center otherXS">
          <div class="header__user-name main-title">{{ user.name }}</div>
          <div class="header__edit-button rounded-button-gray" @click="showEditModal">
            <div class="edit-button__icon icon-pencil custom-icon"></div>
            <span class="edit-button__text">
              {{ $t('dashboard.button.edit') }}
            </span>
          </div>
        </div>
        <div class="text-information__urls flex">
          <div class="questionnaire-url__container">
            <div class="section__title">
              {{ $t('dashboard.button.uniqueUrl') }}
            </div>
            <div class="questionnaire-url">
              <span
                v-tooltip.bottom="{ content: questionnaireUrl, classes: 'link-tooltip' }"
                class="questionnaire-url__text ellipsized"
              >
                {{ questionnaireUrl }}
              </span>
              <div class="icon-copy custom-icon" @click="copyURLToClipboard"></div>
            </div>
          </div>
          <div class="website-url__container">
            <div class="section__title">
              {{ $t('dashboard.label.website') }}
            </div>
            <div v-if="user.personalPage" class="website-url">
              <a
                v-tooltip.bottom="{ content: user.personalPage, classes: 'link-tooltip' }"
                :href="user.personalPage"
                target="_blank"
                class="website-url__text ellipsized"
              >
                {{ user.personalPage }}
              </a>
              <div class="icon-external-link custom-icon cursor-default"></div>
            </div>
            <div v-else class="website-url">
              <div class="website-url__text ellipsized not-answered">
                {{ $t('dashboard.placeholder.na') }}
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="text-information__details flex">
          <div class="details__locations">
            <div class="country__container section">
              <div class="section__title flex--align-center">
                <span>{{ $t('dashboard.label.country') }}</span>
                <div
                  v-tooltip="{
                    content: $t('dashboard.hint.contactSupport'),
                    classes: 'details-tooltip'
                  }"
                  class="icon-info custom-icon"
                ></div>
              </div>
              <div class="country">{{ userCountry }}</div>
            </div>

            <div class="email__container section">
              <div class="section__title flex--align-center">
                <span>{{ $t('dashboard.label.emailAddress') }}</span>
                <div
                  v-tooltip="{
                    content: getMessageWithContactSupport('dashboard.hint.emailAddress'),
                    classes: 'details-tooltip'
                  }"
                  class="icon-info custom-icon"
                ></div>
              </div>
              <div class="email">{{ user.email }}</div>
            </div>

            <div class="secondary-email__container section">
              <div class="section__title flex--align-center">
                <span>{{ $t('dashboard.label.secondaryEmail') }}</span>
                <div
                  v-tooltip="{
                    content: $t('dashboard.hint.secondaryEmailAddress'),
                    classes: 'details-tooltip'
                  }"
                  class="icon-info custom-icon"
                ></div>
              </div>
              <div class="secondary-emails">
                <div
                  v-for="(email, index) in user.secondaryEmails"
                  :key="index"
                  class="secondary-email"
                >
                  {{ email }}
                </div>
              </div>
            </div>

            <div v-if="hasWebshopAccess" class="autopilot-section section">
              <div class="autopilot-section__title section__title flex--align-center">
                <span>{{ $t('dashboard.label.universkinSupport') }}</span>
                <span
                  v-tooltip="{
                    content: $t('dashboard.tooltip.universkinSupport'),
                    classes: 'details-tooltip'
                  }"
                  class="icon-info custom-icon"
                ></span>
              </div>
              <div class="autopilot-section__button">
                <switch-button
                  :label="autopilotLabelText"
                  :value="isAutopilotActivated"
                  @input="updateAutopilot"
                />
              </div>
            </div>
          </div>

          <div class="details__emails">
            <div class="language__container section">
              <div class="section__title">
                {{ $t('dashboard.label.language') }}
              </div>
              <div class="language text-capitalize">{{ questionnaireLanguage }}</div>
            </div>

            <div v-if="isPhoneValid" class="phone-number__container section">
              <div class="section__title flex--align-center">
                <span>{{ $t('dashboard.label.personalPhoneNumber') }}</span>
                <div
                  v-tooltip="{
                    content: $t('dashboard.hint.phoneNumberPersonal')
                  }"
                  class="icon-info custom-icon"
                ></div>
              </div>
              <div v-if="privatePhoneNumber" class="phone-number">
                {{ privatePhoneNumber }}
              </div>
              <div v-else class="not-answered">
                {{ $t('dashboard.placeholder.na') }}
              </div>
            </div>

            <div v-if="isPhoneValid" class="phone-number__container section">
              <div class="section__title flex--align-center">
                <span>{{ $t('dashboard.label.officePhoneNumber') }}</span>
                <div
                  v-tooltip="{ content: $t('dashboard.hint.phoneNumber') }"
                  class="icon-info custom-icon"
                ></div>
              </div>
              <div class="phone-number">{{ officePhoneNumber }}</div>
            </div>

            <div
              v-for="clinic in user.clinicDtos"
              :key="clinic.id"
              class="clinic-locations__container section"
            >
              <div class="clinic-locations section__title">
                <div class="clinic-color" :style="`background-color: ${clinic.clinicColor}`"></div>
                {{ clinic.clinicName }}
                <div
                  v-tooltip="{
                    content: getMessageWithContactSupport('dashboard.hint.locations'),
                    classes: 'details-tooltip'
                  }"
                  class="icon-info custom-icon"
                ></div>
              </div>

              <div v-if="getClinicPhone(clinic)" class="clinic-phone">
                {{ getClinicPhone(clinic) }}
              </div>
              <div v-else class="not-answered">
                {{ $t('dashboard.placeholder.na') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <dashboard-notification />
    <edit-doctor-info-modal />
    <autopilot-toggle-modal v-if="hasWebshopAccess" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import SwitchButton from '@/modules/dashboard/components/common/SwitchButton';
import EditDoctorInfoModal from '@/modules/dashboard/components/profile/EditDoctorInfoModal';
import DashboardNotification from '@/modules/dashboard/components/dashboard/common/DashboardNotification';
import AutopilotToggleModal from '@/modules/dashboard/components/profile/AutopilotToggleModal';

import { buildDoctorImageURL, copyToClipboard } from '@/modules/dashboard/utils';
import { getPatientTransformedPhone } from '@/utils';

import { updateUser } from '@/api';
import { getLanguageNameForLocale } from '@/api/places';
import { displayNameMixin } from '@/mixins/displayNameMixin';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import { TOGGLE_ACTIONS } from '@/modules/dashboard/api/constants';
import { ACCOUNT_NOTIFICATION_DISPLAYING_DURATION } from '@/constants';

export default {
  name: 'PersonalInfo',
  components: {
    AutopilotToggleModal,
    SwitchButton,
    DashboardNotification,
    EditDoctorInfoModal
  },
  mixins: [displayNameMixin],
  computed: {
    ...mapGetters({
      user: rootTypes.getters.GET_USER,
      userId: rootTypes.getters.GET_USER_ID,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS,
      questionnaireUrl: rootTypes.getters.DOCTOR_QUESTIONNAIRE_LINK
    }),
    userCountry() {
      return this.getCountryDisplayName(this.user.country);
    },
    isPhoneValid() {
      return this.user.officePhone && this.user.officePhoneIso;
    },
    officePhoneNumber() {
      const { officePhoneIso, officePhone } = this.user;

      if (!officePhone) {
        return null;
      }

      return getPatientTransformedPhone({ phoneIsoCode: officePhoneIso, phone: officePhone });
    },
    privatePhoneNumber() {
      const { privatePhoneIso, privatePhone } = this.user;

      if (!privatePhone) {
        return null;
      }

      return getPatientTransformedPhone({ phoneIsoCode: privatePhoneIso, phone: privatePhone });
    },
    userPhotoStyles() {
      return { 'background-image': `url(${buildDoctorImageURL(this.user)})` };
    },
    questionnaireLanguage() {
      return getLanguageNameForLocale(this.user.locale).toLowerCase();
    },
    isAutopilotActivated() {
      return this.user.autopilotEnabled;
    },
    autopilotLabelText() {
      return this.isAutopilotActivated
        ? 'dashboard.universkinSupport.active'
        : 'dashboard.universkinSupport.inactive';
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      updateStoreUser: rootTypes.actions.UPDATE_USER,
      resetOpenedPatient: types.actions.RESET_OPENED_PATIENT
    }),
    showEditModal() {
      this.$modal.show('edit-doctor-info-modal');
    },
    openEditPage() {
      this.$router.push({ name: 'EditUserInfo' });
    },
    copyURLToClipboard() {
      copyToClipboard(this.questionnaireUrl);

      this.$notify({
        group: 'dashboard-notification',
        title: this.$t('dashboard.label.copyLink'),
        duration: ACCOUNT_NOTIFICATION_DISPLAYING_DURATION
      });
    },
    getMessageWithContactSupport(translateKey) {
      return `${this.$t(translateKey)}<br><br>${this.$t('dashboard.hint.contactSupport')}`;
    },
    getClinicPhone({ clinicAddress: { phone, phoneIso } }) {
      if (!phone || !phoneIso) {
        return null;
      }

      return getPatientTransformedPhone({ phoneIsoCode: phoneIso, phone });
    },
    async updateAutopilot(isAutopilotActivated) {
      const actionName = isAutopilotActivated ? TOGGLE_ACTIONS.ACTIVATE : TOGGLE_ACTIONS.DEACTIVATE;
      const { confirm } = await this.$asyncModal.show('autopilot-toggle-modal', { actionName });

      if (!confirm) {
        return;
      }

      await this.setLoading(true);

      await updateUser({ ...this.user, autopilotEnabled: isAutopilotActivated });
      await this.updateStoreUser(this.user.id);

      await this.setLoading(false);

      this.$notify({
        group: 'dashboard-notification',
        title: this.$t(this.autopilotLabelText),
        duration: ACCOUNT_NOTIFICATION_DISPLAYING_DURATION
      });
    }
  },
  metaInfo: {
    title: 'My account',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/css/common/photos.css';
@import '../../../../assets/css/common/tooltips.css';

.main-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;

  color: $main-title-color;
}

.section {
  margin-bottom: 15px;

  &__title {
    font-size: 13px;
    line-height: 150%;
    font-weight: 300;

    color: $active-title-color;

    margin-bottom: 4px;
  }
}

.autopilot-section {
  &__title {
    margin-bottom: 9px;
  }
}

.personal-info-container {
  width: 100%;
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;
  display: flex;
  flex-direction: column;
}

.personal-info-content {
  padding: 20px 40px;
  display: flex;
  background-color: $white;
  border-radius: 10px;
}

.copy-to-clipboard-tooltip {
  position: fixed;
  font-size: 11px;
  line-height: 150%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  margin-bottom: 20px;
}

.header__edit-button {
  display: flex;
  align-items: center;
}

.edit-button__icon,
.edit-button__text {
  margin-right: 9px;
}

.photo__container {
  margin-right: 50px;
}

.text-information {
  flex-grow: 1;

  &__header {
    margin-bottom: 30px;
  }
}

.questionnaire-url,
.website-url,
.clinic__container,
.clinic-locations {
  display: flex;
  align-items: center;
}

.questionnaire-url,
.website-url {
  max-width: 330px;

  &__text {
    color: $links-color;
  }
}

.website-url__text:hover {
  color: $hovered-link-color;
}

.questionnaire-url__container,
.website-url__container,
.details__locations,
.details__emails {
  flex-grow: 1;
  flex-basis: 0;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.secondary-email {
  margin-bottom: 7px;
}

.clinic-color {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 5px;
}

.clinic__container {
  margin-bottom: 7px;
}

.icon-copy,
.icon-external-link {
  width: 20px;
  background-size: auto;
}

.not-answered {
  color: $icon-grey-color;

  &:hover {
    color: $icon-grey-color;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .personal-info-container {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .questionnaire-url,
  .website-url {
    max-width: 220px;
  }
}

@media screen and (max-width: 767px) {
  .personal-info-container {
    background-color: $white;
    font-size: 13px;
    line-height: 15px;
  }

  .personal-info-content {
    margin-top: 10px;
    padding: 0 20px;
    flex-direction: column;
  }

  .doctor-photo-information {
    display: flex;
  }

  .photo__container {
    margin-right: 20px;
    width: 100px;
    height: 100px;
  }

  .text-information__header {
    flex-direction: column;
    justify-content: left;
    align-items: self-start;
  }

  .header__user-name {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  .text-information__urls {
    margin-top: 20px;
    flex-direction: column;
  }

  .website-url__container {
    margin-top: 15px;
  }

  .text-information__details {
    flex-direction: column;
  }

  .section {
    margin-bottom: 20px;

    &__title {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      margin-bottom: 6px;
    }
  }

  .otherXS {
    display: none;
  }
}

@media screen and (min-width: 767px) {
  .personal-info-container {
    margin-bottom: 37px;
  }

  .onlyXS {
    display: none;
  }
}
</style>
