<template>
  <modal
    name="authentication-recovery-codes-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    :click-to-close="false"
    width="800px"
    height="680px"
    @before-open="onBeforeOpen"
  >
    <div class="modal-container">
      <two-factor-authentication-recovery-codes
        :is-header-with-back-button="isHeaderWithBackButton"
        :has-authentication-enabled="hasAuthenticationEnabled"
      />
    </div>
  </modal>
</template>

<script>
import TwoFactorAuthenticationRecoveryCodes from '@/modules/dashboard/components/two-factor-authentication/TwoFactorAuthenticationRecoveryCodes';

export default {
  name: 'AuthenticationRecoveryCodesModal',
  components: { TwoFactorAuthenticationRecoveryCodes },
  data() {
    return {
      isHeaderWithBackButton: false,
      hasAuthenticationEnabled: false
    };
  },
  methods: {
    onBeforeOpen({ params: { isHeaderWithBackButton = false, hasAuthenticationEnabled = false } }) {
      this.isHeaderWithBackButton = isHeaderWithBackButton;
      this.hasAuthenticationEnabled = hasAuthenticationEnabled;
    }
  }
};
</script>
