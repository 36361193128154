<template>
  <modal
    name="authentication-scan-code-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    :click-to-close="false"
    width="800px"
    height="680px"
  >
    <div class="modal-container">
      <two-factor-authentication-scan-code />
    </div>
  </modal>
</template>

<script>
import TwoFactorAuthenticationScanCode from '@/modules/dashboard/components/two-factor-authentication/TwoFactorAuthenticationScanCode';

export default {
  name: 'AuthenticationScanCodeModal',
  components: { TwoFactorAuthenticationScanCode }
};
</script>
