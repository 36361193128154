<template>
  <notifications group="dashboard-notification" width="100%" :max="3" position="bottom center">
    <template slot="body" slot-scope="props">
      <div class="tooltip dashboard-tooltip">
        <div class="tooltip-inner">{{ props.item.title }}</div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'DashboardNotification'
};
</script>

<style scoped>
@import '../../../../../assets/css/common/tooltips.css';
</style>
