<template>
  <div class="switch-button">
    <toggle-switch class="switch-button__toggle" :value="value" @input="$emit('input', $event)" />
    <span class="switch-button__label">{{ $t(label) }}</span>
  </div>
</template>

<script>
import ToggleSwitch from '@/modules/dashboard/components/common/ToggleSwitch';

export default {
  name: 'SwitchButton',
  components: { ToggleSwitch },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-button {
  display: flex;
  align-items: center;

  &__label {
    margin: 1px 0 0 8px;
  }
}
</style>
