<template>
  <section class="authentication flex-column">
    <component
      :is="headerComponent"
      class="authentication__modal-header"
      :previous-modal-name="previousModalName"
      current-modal-name="two-factor-authentication-modal-set-up"
      is-custom-close-handler
      @close-modal="closeModal"
    >
      <span slot="header-title" class="title-text">
        {{ $t('dashboard.label.twoFactorAuthentication') }}
      </span>
    </component>
    <div class="divider header-divider"></div>

    <div class="authentication-content content">
      <div class="content__title">{{ $t('dashboard.label.twoFactorAuthentication') }}</div>
      <div class="content__description">
        <i18n path="dashboard.authentication.authenticationAdds" tag="span">
          <span class="bold">
            {{ $t('dashboard.authentication.application') }}
          </span>
        </i18n>
      </div>
      <div class="authentication-content__steps steps">
        <div class="steps__step step">
          <span class="step__number">1</span>
          <p class="step__description">
            {{ $t('dashboard.authentication.whenYouSignIn') }}
          </p>
        </div>
        <div class="steps__step step">
          <span class="step__number">2</span>
          <p class="step__description">
            <i18n path="dashboard.authentication.nextYouNeed" tag="span">
              <span class="bold">
                {{ $t('dashboard.authentication.additionalCode') }}
              </span>
            </i18n>
          </p>
        </div>
        <div class="steps__step step">
          <span class="step__number">3</span>
          <p class="step__description">
            {{ $t('dashboard.authentication.onceYouEnter') }}
          </p>
        </div>
      </div>
      <div class="content__description--secondary">
        <div>{{ $t('dashboard.authentication.pleaseInstall') }}</div>
        <div>
          <i18n path="dashboard.authentication.weRecommend" tag="p">
            <a class="link" :href="googleAuthenticatorAppLink" target="_blank">
              {{ $t('dashboard.label.googleAuthenticator') }}
            </a>
            <a href="https://authy.com/download/" target="_blank" class="link">{{
              $t('dashboard.label.authy')
            }}</a>
            <a href="https://lastpass.com/auth/" target="_blank" class="link">{{
              $t('dashboard.label.lastPass')
            }}</a>
          </i18n>
        </div>
      </div>
    </div>
    <footer class="authentication__footer footer">
      <div class="divider"></div>
      <div class="footer__button-container flex--center--center">
        <button class="action-button rounded-button-white" @click="authenticationSetUp">
          {{ $t('dashboard.label.setupAuthentication') }}
        </button>
      </div>
    </footer>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';

import { handleRoutingInfo, isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

import {
  PROFILE_ROUTING_INFO,
  RECOVERY_CODES_ROUTING_INFO
} from '@/modules/dashboard/api/constants';

export default {
  name: 'TwoFactorAuthenticationSetUp',
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: false
    },
    previousModalName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      userLanguage: rootTypes.getters.GET_USER_LANGUAGE
    }),
    headerComponent() {
      return this.isHeaderWithBackButton ? ModalHeaderWithPrevious : ModalHeader;
    },
    googleAuthenticatorAppLink() {
      return `https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=${this.userLanguage}`;
    }
  },
  methods: {
    ...mapActions({
      generateRecoveryCodes: rootTypes.actions.GENERATE_USER_RECOVERY_CODES
    }),
    closeModal() {
      if (isMobileDevice()) {
        handleRoutingInfo(PROFILE_ROUTING_INFO, this.$router, this.$modal);
      }

      this.$modal.hide('two-factor-authentication-set-up-modal');
    },
    authenticationSetUp() {
      this.generateRecoveryCodes(this.userId);

      handleRoutingInfo(
        {
          ...RECOVERY_CODES_ROUTING_INFO,
          params: { isHeaderWithBackButton: true, hasAuthenticationEnabled: false }
        },
        this.$router,
        this.$modal
      );
      this.$modal.hide('two-factor-authentication-set-up-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/text';

.authentication {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-bold {
  font-weight: 600;
}

.link {
  color: $links-color;
  font-weight: 600;
}

.content {
  flex: 1;
  margin: 50px 30px 20px;
  font-size: 13px;
  line-height: 22px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $main-title-color;
  }

  &__description {
    max-width: 440px;
    margin: 0 auto 30px;
    color: $text-color;

    &--secondary {
      max-width: 474px;
      margin: 0 auto;
    }
  }
}

.authentication-content__steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.action-button {
  width: 270px;
  height: 35px;
  padding: 0;
  font-weight: bold;
  border: none;
  background-color: $hovered-button-color;

  &:hover {
    background-color: $hovered-button-blue-color;
    border: none;
  }
}

.step {
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 200px;
  padding: 25px 17px;
  background-color: $hovered-button-color;

  &__number {
    margin-bottom: 6px;
    text-align: center;
    font-weight: 250;
    font-size: 48px;
    line-height: 58px;
    color: $grey-color;
  }

  &__description {
    font-size: 12px;
    line-height: 150%;
  }
}

.footer {
  height: 75px;

  &__button-container {
    height: 100%;
  }
}

.header-divider {
  display: none;
}

@media screen and (max-width: 767px) {
  .content {
    width: 328px;
    margin: 28px auto;

    &__description {
      margin: 0 auto 24px;
    }
  }

  .authentication-content__steps {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .step {
    width: 100%;
    height: 105px;
    flex-direction: row;
    align-items: center;
    padding: 0 32px;
    margin-bottom: 7px;
    background-color: $blue-color;

    &__number {
      margin: 0 21px 0 0;
    }

    &__description {
      margin: 0;
      text-align: left;
      font-size: 12px;
      line-height: 150%;
    }
  }

  .action-button {
    background-color: $blue-color;
  }

  .footer {
    height: 80px;
  }

  .divider {
    display: block;
    border: 0.5px solid $dim-white;
  }
}
</style>
