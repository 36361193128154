<template>
  <div class="notification-row" :class="{ read: notification.isRead }" @click="showDetails">
    <notification-subject class="notification-subject" :subject="notification.subject" />
    <div class="patient-name ellipsized">
      <span class="patient-name__text">{{ notification.patientName }}&nbsp;</span>
      <br class="onlyXS" />
      <span class="patient-name__message ellipsized">{{ notificationMessage }}</span>
    </div>
    <div class="notification-date">
      <p class="notification-date__text no-search-field">{{ date }}</p>
    </div>
    <div class="dots-menu patient-detail" @click.stop>
      <div
        class="vertical-dots"
        tabindex="-1"
        @click="toggleMarkAsReadDropdown"
        @blur="disableMarkAsReadDropdown"
      ></div>
      <div
        class="main-dropdown dropdown-menu dropdown-menu-right"
        :class="{ visible: markAsReadDropdownVisible }"
        @mousedown.prevent.stop
      >
        <div
          class="dropdown-item main-dropdown__option"
          :class="{ none: isMarkAsReadOptionInvisible }"
          @mousedown.prevent="markNotification({ isRead: true })"
        >
          {{ $t('dashboard.action.markAsRead') }}
        </div>
        <div
          class="dropdown-item main-dropdown__option"
          :class="{ none: !isMarkAsReadOptionInvisible }"
          @mousedown.prevent="markNotification({ isRead: false })"
        >
          {{ $t('dashboard.action.markAsUnread') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import differenceInHours from 'date-fns/difference_in_hours';

import { mapActions, mapGetters, mapMutations } from 'vuex';

import NotificationSubject from '@/modules/dashboard/components/notifications/NotificationSubject';

import { markNotification } from '@/modules/dashboard/api';

import { isMobileDevice } from '@/modules/dashboard/utils';
import { formatDate } from '@/modules/dashboard/utils/date-utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import { FULL_DATE_PATTERN, HOURS_DATE_PATTERN } from '@/constants';
import { PATIENT_TAB_NAMES, NOTIFICATION_TYPE } from '@/modules/dashboard/api/constants';

const NOTIFICATION_MESSAGE_KEY = {
  [NOTIFICATION_TYPE.NEW_QUESTIONNAIRE]: 'dashboard.notification.filledNewQuestionnaire',
  [NOTIFICATION_TYPE.TREATMENT_COMPLETED]: 'dashboard.notification.completedTreatment',
  [NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY]: 'dashboard.notification.orderUnderPreparation'
};

export default {
  name: 'Notification',
  components: {
    NotificationSubject
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      markAsReadDropdownVisible: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    date() {
      const { eventDate } = this.notification;
      const difference = differenceInHours(new Date(), eventDate);
      const datePattern = difference < 24 ? HOURS_DATE_PATTERN : FULL_DATE_PATTERN;

      return formatDate(eventDate, datePattern);
    },
    isNewQuestionnaire() {
      return this.notification.subject === NOTIFICATION_TYPE.NEW_QUESTIONNAIRE;
    },
    isMarkAsReadOptionInvisible() {
      return this.notification.isRead;
    },
    notificationMessage() {
      const translationKey = NOTIFICATION_MESSAGE_KEY[this.notification.subject];

      return this.$t(translationKey);
    }
  },
  methods: {
    ...mapActions({
      setPatientCardActiveTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      resetCreateTreatmentSelections: dashTypes.actions.RESET_CREATE_TREATMENT_SELECTIONS,
      resetActiveDiagnosticId: dashTypes.actions.RESET_ACTIVE_DIAGNOSTIC_ID,
      resetActiveTreatment: dashTypes.actions.RESET_ACTIVE_TREATMENT,
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      setLoading: rootTypes.actions.SET_LOADING
    }),
    ...mapMutations({
      resetCurrentPatient: dashTypes.mutations.RESET_CURRENT_PATIENT
    }),
    async showDetails() {
      const { isRead, patientId } = this.notification;

      this.setLoading(true);

      if (!isRead) {
        this.markNotification({ isRead: true });
      }

      await this.resetActiveTreatment();
      await this.resetActiveDiagnosticId();
      await this.resetCreateTreatmentSelections();

      this.resetCurrentPatient();

      await this.loadPatient(patientId);

      this.setLoading(false);

      if (isMobileDevice()) {
        const mobileRouteName = this.isNewQuestionnaire ? 'Diagnostics' : 'TreatmentHistory';

        this.$router.push({
          name: mobileRouteName,
          params: {
            id: patientId
          }
        });

        return;
      }

      /*
        This is a workaround for a updating tab data bug when you are redirecting to
        TreatmentHistory/Diagnostic tab from same tab of previous Patient through
        Bell Notification. (UNK-2539)
      */
      await this.setPatientCardActiveTab(PATIENT_TAB_NAMES.COMMENTS);

      const patientTab = this.isNewQuestionnaire
        ? PATIENT_TAB_NAMES.DIAGNOSTICS
        : PATIENT_TAB_NAMES.TREATMENT_HISTORY;

      this.setPatientCardActiveTab(patientTab);

      this.$router.push({
        name: 'PatientCard',
        params: {
          id: patientId
        }
      });
    },
    async markNotification({ isRead }) {
      await markNotification(this.notification, { isRead, doctorId: this.userId });

      this.disableMarkAsReadDropdown();
      this.$emit('mark-notification', { isRead });
    },
    toggleMarkAsReadDropdown() {
      this.markAsReadDropdownVisible = !this.markAsReadDropdownVisible;
    },
    disableMarkAsReadDropdown() {
      this.markAsReadDropdownVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/dropdowns.css';
@import '../../../../assets/scss/helpers/colors';

.main-dropdown {
  padding: 0;

  &__option {
    padding: 20px 15px;
  }
}

.read {
  background: $dim-blue;
}

.patient-name {
  flex-grow: 1;

  &__text {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }

  &__message {
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;
  }
}

.notification-subject {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;

  margin-left: 15px;
  margin-right: 12px;
  width: 15px;
}

.notification-date__text {
  margin-bottom: 0;
  margin-right: 15px;

  font-weight: 300;
  font-size: 13px;
  line-height: 100%;
  white-space: nowrap;

  color: $light-slate-grey-color;
}

.notification-row {
  height: 50px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  color: $black-olive-color;

  &:hover {
    color: $nevada;
  }
}

.dots-menu {
  position: relative;
  margin-right: 15px;
}

@media screen and (max-width: 767px) {
  .notification-date__text {
    font-size: 10px;
    line-height: 12px;
  }

  .notification-row {
    min-height: 60px;
    padding: 15px 5px 13px 20px;
  }

  .notification-subject {
    margin-left: 0;
    margin-right: 15px;
  }

  .patient-name__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
  }

  .patient-name__message {
    display: inline-block;
    margin-top: 5px;
    font-size: 10px;
    line-height: 12px;
  }

  .dots-menu {
    margin-right: 5px;
    padding-bottom: 3px;
  }

  .notification-date__text {
    margin-right: 10px;
    padding-bottom: 15px;
  }
}
</style>
