<template>
  <div class="copy">
    <div class="copy-button" @click="copyURLToClipboard">
      <slot name="copy-button"></slot>
    </div>
    <div v-if="isCopiedToClipboard" class="tooltip copy-to-clipboard-tooltip">
      <div class="tooltip-inner tooltip-inner_center">
        <slot> {{ $t('dashboard.label.copyLink') }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@/modules/dashboard/utils';
import { ACCOUNT_NOTIFICATION_DISPLAYING_DURATION } from '@/constants';

export default {
  name: 'CopyToClipboard',
  props: {
    textToCopy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isCopiedToClipboard: false
    };
  },
  methods: {
    copyURLToClipboard() {
      copyToClipboard(this.textToCopy);

      this.isCopiedToClipboard = true;

      setTimeout(() => {
        this.isCopiedToClipboard = false;
      }, ACCOUNT_NOTIFICATION_DISPLAYING_DURATION);
    }
  }
};
</script>

<style scoped>
@import '../../../../assets/css/common/tooltips.css';
</style>
