<template>
  <div>
    <div
      class="phone-number__container rounded-input full-width"
      :class="{
        'error-highlight': isPhoneHasErrors,
        'transparent-input': transparent,
        'input-focus-within': shouldHighlightOnFocus
      }"
      tabindex="-1"
    >
      <span class="phone-number__phone-code">+{{ phoneCode }}</span>
      <scrollable-dropdown :select-element-height="15" class="phone-numbers-dropdown">
        <template slot="button">
          <div class="phone-numbers-dropdown__toggle pointer" data-toggle="dropdown">
            <span class="fas fa-chevron-down arrow-down-icon mobile-arrow-down"></span>
          </div>
        </template>
        <template slot="list-elements">
          <li v-for="countryIsoCode in countries" :key="countryIsoCode">
            <div class="countries-dropdown-option" @click="updateIsoCode(countryIsoCode)">
              {{ getCountryLabel(countryIsoCode) }}
            </div>
          </li>
        </template>
      </scrollable-dropdown>

      <input
        :id="phoneFieldId"
        v-model="phoneField"
        v-validate.disable="phoneValidation"
        v-allow-only-reg-exp="onlyNumberRegExp"
        v-phone-number
        :placeholder="placeholder"
        :name="phoneFieldName"
        type="tel"
        class="phone-number__input main-placeholder transparent-input"
        @beforeinput="validatePhoneNumber"
        @input="clearPhoneErrors"
        @blur="updatePhoneNumber(phoneField)"
        @keydown.space.enter.prevent="updatePhoneNumber(phoneField)"
      />
    </div>
    <p v-if="isPhoneHasErrors" class="error-message">
      <span class="fas fa-exclamation-triangle"></span>
      <span>{{ $t('dashboard.warning.requiredField') }}</span>
    </p>
  </div>
</template>

<script>
import { countries } from 'countries-list';

import ScrollableDropdown from '@/modules/dashboard/components/common/ScrollableDropdown';

import { getPhoneCodeFromISO } from '@/utils';
import { deleteSpaces, transformPhone } from '@/modules/dashboard/utils';

import { SORTED_PHONE_CODES_BY_COUNTRY_NAME, NUMBER_ONLY_REG_EXP } from '@/constants';

export default {
  name: 'PhoneEditField',
  components: { ScrollableDropdown },
  inject: ['$validator'],
  props: {
    isoCode: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    phoneFieldName: {
      type: String,
      default: 'phone'
    },
    phoneFieldId: {
      type: String,
      default: 'add-phone-number'
    },
    shouldHighlightOnFocus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phoneField: '',
      countries: SORTED_PHONE_CODES_BY_COUNTRY_NAME,
      onlyNumberRegExp: NUMBER_ONLY_REG_EXP
    };
  },
  computed: {
    phoneCode() {
      return getPhoneCodeFromISO(this.isoCode);
    },
    isPhoneHasErrors() {
      return this.errors.has(this.phoneFieldName);
    },
    phoneValidation() {
      return {
        required: this.isRequired
      };
    }
  },
  watch: {
    phone(newValue) {
      this.phoneField = transformPhone(newValue);
    }
  },
  mounted() {
    this.phoneField = transformPhone(this.phone);
  },
  methods: {
    getCountryLabel(isoCode) {
      const { name, phone } = countries[isoCode.toUpperCase()];

      return `${name} (+${phone})`;
    },
    validatePhoneNumber(event) {
      if (event.data && !this.onlyNumberRegExp.test(event.data)) {
        event.preventDefault();
      }
    },
    updatePhoneNumber(phoneNumber) {
      const phoneNumberWithoutSpaces = deleteSpaces(phoneNumber);

      this.$emit('update-phone-number', phoneNumberWithoutSpaces);
    },
    updateIsoCode(isoCode) {
      this.$emit('update-iso-code', isoCode);
    },
    clearPhoneErrors() {
      if (this.isPhoneHasErrors) {
        this.errors.remove('phone');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/inputs.css';
@import '../../../../assets/css/common/errors';

.phone-number {
  &__container {
    display: flex;
    align-items: center;
    max-width: 240px;
  }

  &__phone-code {
    font-size: 15px;
    color: $text-color;
  }

  &__input {
    width: 100%;
    padding: 5px 0;
    font-size: 15px;
    line-height: 17px;
  }
}

.countries-dropdown-option {
  font-size: 15px;
  line-height: 17px;
  color: $text-color;
  white-space: normal;
  cursor: pointer;
  padding: 10px 15px;
}

.arrow-down-icon {
  font-size: 10px;
  cursor: pointer;
  color: $light-grey-color;
}

.mobile-arrow-down {
  margin-left: 11px;
  margin-right: 11px;
}

.phone-numbers-dropdown {
  &__toggle {
    width: 100%;
    height: 100%;
  }
}

.phone-numbers-dropdown > .dropdown-menu {
  margin-top: 10px;
  left: -30px;
  width: 240px;
}

@media screen and (max-width: 767px) {
  .phone-number {
    &__container {
      max-width: none;
      width: 100%;
    }

    &__phone-code,
    &__input {
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;
    }

    &__input {
      width: 135px;
    }
  }

  .countries-dropdown-option {
    font-size: 13px;
    line-height: 15px;
  }
}
</style>
