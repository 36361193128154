<template>
  <section class="scan-code-wrapper flex-column">
    <component
      :is="headerComponent"
      current-modal-name="authentication-scan-code-modal"
      previous-modal-name="authentication-recovery-codes-modal"
      is-custom-close-handler
      @close-modal="onCloseModal"
    >
      <span slot="header-title" class="title-text">
        {{ $t('dashboard.label.twoFactorAuthentication') }}
      </span>
    </component>
    <div class="scan-code">
      <div class="scan-code__title">{{ $t('dashboard.label.scanBarcode') }}</div>
      <div class="scan-code__description-main mb10">
        {{ $t('dashboard.label.scanImage') }}
      </div>
      <span class="scan-code__link" @click="folowManualFlow">{{
        $t('dashboard.label.cantScan')
      }}</span>
      <div class="scan-code__code-wrapper">
        <img :src="authenticationCode.qrCode" alt="QR code" class="code-image" />
      </div>

      <div class="scan-code__description-main mb10">
        {{ $t('dashboard.label.EnterSixDigitCode') }}
      </div>

      <div class="scan-code__description-secondary description-secondary">
        {{ $t('dashboard.authentication.afterScanning') }}
      </div>
      <input
        ref="input"
        v-allow-only-reg-exp="/^([0-9])$/"
        maxlength="12"
        :value="formattedVerificationCode"
        class="form-control scan-code__input"
        @input="onAuthenticationCodeInput"
      />

      <div v-if="hasInvalidVerificationCode" class="scan-code__error-message">
        <span>{{ $t('dashboard.authentication.twoFactorAuthenticationFailed') }}</span>
        <span>{{ $t('dashboard.authentication.pleaseTryAgain') }}</span>
      </div>
    </div>
    <footer class="scan-code__footer footer ">
      <div class="divider"></div>
      <div class="footer__button-container flex--center--center">
        <button
          class="scan-code__action-button rounded-button-white"
          @click="enableTwoFactorAuthentication"
        >
          {{ $t('dashboard.label.enable') }}
        </button>
      </div>
    </footer>
    <authentication-confirm-modal />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import AuthenticationConfirmModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationConfirmModal';

import { confirmTwoFactorAuthenticationSetup } from '@/api';

import {
  transformVerificationCode,
  isMobileDevice,
  deleteSpaces,
  handleRoutingInfo
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

import { AUTHENTICATION_MANUAL_FLOW_ROUTING_INFO } from '@/modules/dashboard/api/constants';

export default {
  name: 'TwoFactorAuthenticationScanCode',
  components: { AuthenticationConfirmModal },
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      verificationCode: '',
      hasInvalidVerificationCode: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      authenticationCode: rootTypes.getters.GET_USER_AUTHENTICATION_CODE
    }),
    headerComponent() {
      return this.isHeaderWithBackButton ? ModalHeaderWithPrevious : ModalHeader;
    },
    formattedVerificationCode() {
      return transformVerificationCode(this.verificationCode);
    }
  },
  methods: {
    ...mapActions({
      getUpdatedUser: rootTypes.actions.UPDATE_USER
    }),
    onCloseModal() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'UserProfile' });

        return;
      }

      this.$modal.hide('authentication-scan-code-modal');
    },
    async enableTwoFactorAuthentication() {
      const {
        data: { successful }
      } = await confirmTwoFactorAuthenticationSetup(this.userId, {
        confirmationCode: deleteSpaces(this.verificationCode)
      });

      if (!successful) {
        this.hasInvalidVerificationCode = true;

        return;
      }

      await this.getUpdatedUser(this.userId);

      if (!isMobileDevice()) {
        this.onCloseModal();
      }

      this.$modal.show('authentication-confirm-modal');
    },
    onAuthenticationCodeInput() {
      this.hasInvalidVerificationCode = false;

      this.verificationCode = get(event, ['target', 'value']);
    },
    folowManualFlow() {
      handleRoutingInfo(
        {
          ...AUTHENTICATION_MANUAL_FLOW_ROUTING_INFO,
          params: { isHeaderWithBackButton: true, hasAuthenticationEnabled: false }
        },
        this.$router,
        this.$modal
      );

      this.$modal.hide('authentication-scan-code-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/text';

.scan-code-wrapper {
  height: 100%;
}

.scan-code {
  flex: 1;
  width: 470px;
  margin: 34px auto 10px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $main-title-color;
  }

  &__description-main {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: $text-color;
  }

  &__description-secondary {
    margin: 0 auto;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: $text-color;
  }

  &__code-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    margin: 20px auto;
    padding: 30px;
    border: 1px solid $light-blue;
  }

  &__link {
    font-weight: bold;
    color: $links-color;
    cursor: pointer;
  }

  &__action-button {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    border: none;
    background-color: $hovered-button-color;

    &:hover {
      background-color: $hovered-button-blue-color;
      border: none;
    }
  }

  &__error-message {
    font-size: 15px;
    line-height: 17px;
    color: $error-color;
  }

  &__input {
    width: 115px;
    height: auto;
    padding: 8px 10px;
    margin: 10px auto;
    border: 1px solid $light-blue;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    color: $light-grey-color;
  }
}

.code-image {
  width: 150px;
  height: 150px;
}

.description-secondary {
  width: 300px;
}

.footer {
  height: 75px;

  &__button-container {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .scan-code {
    width: 320px;
    margin-bottom: 110px;

    &__link {
      font-size: 13px;
      line-height: 22px;
    }

    &__error-message {
      display: flex;
      flex-direction: column;
    }
  }

  .footer {
    height: 80px;
  }
}
</style>
