<template>
  <div class="clinic-phone-selector">
    <div class="clinic-phone-selector__title section-title">
      <span>{{ value.clinicName }}</span>
      <div
        v-tooltip="{
          content: `${$t('dashboard.hint.locations')}<br><br>${$t(
            'dashboard.hint.contactSupport'
          )}`,
          classes: 'details-tooltip'
        }"
        class="icon-info custom-icon"
      ></div>
    </div>

    <phone-edit-field
      class="clinic-phone-selector__phone-field"
      :phone="value.clinicAddress.phone"
      :iso-code="value.clinicAddress.phoneIso"
      :phone-field-id="`clinic${value.id}`"
      :phone-field-name="`clinic${value.id}`"
      is-required
      @update-iso-code="updateClinicAddressField('phoneIso', $event)"
      @update-phone-number="updateClinicAddressField('phone', $event)"
    />
  </div>
</template>

<script>
import PhoneEditField from '@/modules/dashboard/components/profile/PhoneEditField';

export default {
  name: 'ClinicPhoneSelector',
  components: { PhoneEditField },
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    updateClinicAddressField(fieldName, value) {
      const clinicAddress = { ...this.value.clinicAddress, [fieldName]: value };

      this.$emit('input', { ...this.value, clinicAddress });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/profile/edit-doctor-shared';
</style>
