<template>
  <section class="recovery-codes-wrapper flex-column">
    <component
      :is="headerComponent"
      current-modal-name="authentication-recovery-codes-modal"
      previous-modal-name="two-factor-authentication-set-up-modal"
      is-custom-close-handler
      @close-modal="onClose"
    >
      <span slot="header-title" class="title-text">
        {{ sectionTitle }}
      </span>
    </component>

    <div class="recovery-codes">
      <div class="recovery-codes__title">{{ $t('dashboard.label.recoveryCodes') }}</div>
      <div class="recovery-codes__description">
        <div>{{ $t(recoveryCodesData.descriptionOne) }}</div>
        <div>{{ $t(recoveryCodesData.descriptionTwo) }}</div>
      </div>

      <div id="recovery-code-list" class="recovery-codes__list style-list-none flex-column">
        <div
          v-for="{ code, valid } in recoveryCodes"
          :key="code"
          class="recovery-codes__list-item"
          :class="{ 'recovery-codes__list-item--invalid': !valid }"
        >
          {{ code }}
        </div>
      </div>

      <div class="recovery-codes__handle-code-buttons">
        <div class="flex justify-content--space-around mb20">
          <button class="rounded-button-white" @click="downloadCodes">
            {{ $t('dashboard.label.download') }}
          </button>
          <button class="rounded-button-white" @click="printCodes">
            {{ $t('dashboard.label.print') }}
          </button>
          <copy-to-clipboard :text-to-copy="recoveryCodesString">
            <div slot="copy-button">
              <button class="rounded-button-white" @click="copyCodes">
                {{ $t('dashboard.label.copy') }}
              </button>
            </div>
            <div>{{ $t('dashboard.label.recoveryCodesCopied') }}</div>
          </copy-to-clipboard>
        </div>
        <button
          v-if="hasAuthenticationEnabled"
          class="rounded-button-gray mb20"
          @click="generateCodes"
        >
          {{ $t('dashboard.label.generateNewRecoveryCode') }}
        </button>
      </div>

      <div class="recovery-codes__hint">
        <div>{{ $t(recoveryCodesData.hintOne) }}</div>
        <div>{{ $t(recoveryCodesData.hintTwo) }}</div>
      </div>
    </div>

    <footer class="recovery-codes__footer footer">
      <div class="divider"></div>
      <div class="footer__button-container flex--center--center">
        <button
          v-if="hasAuthenticationEnabled"
          class="recovery-codes__button-cancel cancel-button"
          @click="onClose"
        >
          {{ $t('dashboard.action.close') }}
        </button>
        <button
          v-else
          class="recovery-codes__button-continue rounded-button-white"
          :disabled="!isRecoveryCodesHandled"
          @click="onContinue"
        >
          {{ $t('dashboard.action.next') }}
        </button>
      </div>
    </footer>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import CopyToClipboard from '@/modules/dashboard/components/common/CopyToClipboard';

import {
  isMobileDevice,
  downloadFile,
  handleRoutingInfo,
  printFile
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

import { AUTHENTICATION_SCAN_CODE_ROUTING_INFO } from '@/modules/dashboard/api/constants';

const AUTHENTICATION_ENABLED_CONFIG = {
  descriptionOne: 'dashboard.authentication.treatYourRecoveryCodes',
  hintOne: 'dashboard.authentication.whenYouGenerate',
  hintTwo: 'dashboard.authentication.yourOldCodes'
};

const AUTHENTICATION_DISABLED_CONFIG = {
  descriptionOne: 'dashboard.authentication.recoveryCodesUsed',
  descriptionTwo: 'dashboard.authentication.eachCodeCanBeUsed',
  hintOne: 'dashboard.authentication.handleCode',
  hintTwo: 'dashboard.authentication.treatYourRecoveryCodes'
};

export default {
  name: 'TwoFactorAuthenticationRecoveryCodes',
  components: { CopyToClipboard },
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: true
    },
    hasAuthenticationEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRecoveryCodesHandled: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      recoveryCodes: rootTypes.getters.GET_USER_RECOVERY_CODES
    }),
    headerComponent() {
      return this.isHeaderWithBackButton ? ModalHeaderWithPrevious : ModalHeader;
    },
    sectionTitle() {
      const twoAuthLabel = this.$t('dashboard.label.twoFactorAuthentication');
      const recoveryCodesLabel = this.$t('dashboard.label.recoveryCodes');

      return this.hasAuthenticationEnabled
        ? `${twoAuthLabel}. ${recoveryCodesLabel}`
        : twoAuthLabel;
    },
    recoveryCodesData() {
      return this.hasAuthenticationEnabled
        ? AUTHENTICATION_ENABLED_CONFIG
        : AUTHENTICATION_DISABLED_CONFIG;
    },
    recoveryCodesString() {
      return this.recoveryCodes.map(({ code }) => code).join(',');
    }
  },
  methods: {
    ...mapActions({
      generateRecoveryCodes: rootTypes.actions.GENERATE_USER_RECOVERY_CODES,
      setUpTwoFactorAuthentication: rootTypes.actions.SET_UP_TWO_FACTOR_AUTHENTICATION
    }),
    downloadCodes() {
      this.isRecoveryCodesHandled = true;

      const blob = new Blob([this.recoveryCodesString], { type: 'text/plain' });
      const fileName = 'Recovery codes.txt';

      downloadFile(blob, { fileName, shouldOpenFile: false });
    },
    printCodes() {
      this.isRecoveryCodesHandled = true;
      printFile('recovery-code-list');
    },
    copyCodes() {
      this.isRecoveryCodesHandled = true;
    },
    onClose() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'UserProfile' });

        return;
      }

      this.$modal.hide('authentication-recovery-codes-modal');
    },
    generateCodes() {
      this.generateRecoveryCodes(this.userId);
    },
    onContinue() {
      this.setUpTwoFactorAuthentication(this.userId);

      handleRoutingInfo(
        {
          ...AUTHENTICATION_SCAN_CODE_ROUTING_INFO,
          params: { isHeaderWithBackButton: false, hasAuthenticationEnabled: false }
        },
        this.$router,
        this.$modal
      );

      this.$modal.hide('authentication-recovery-codes-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/text';

.recovery-codes-wrapper {
  height: 100%;
}

.recovery-codes {
  flex: 1;
  width: 400px;
  margin: 34px auto 10px;
  font-size: 13px;
  line-height: 22px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  &__description {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 22px;
  }

  &__hint {
    padding: 10px 55px;
    font-size: 12px;
    line-height: 150%;
    color: $text-color;
    background-color: $beige;
    border-radius: 8px;
  }

  &__list {
    flex-wrap: wrap;
    width: 400px;
    height: 180px;
    margin: 0 auto 20px;
    padding: 30px 70px;
    background-color: $background;
    border: 1px dashed $border-light-grey-color;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    list-style: none;

    &-item {
      &--invalid {
        opacity: 0.2;
      }
    }
  }

  &__button-continue {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    border: none;
    background-color: $hovered-button-color;

    &:disabled {
      &:hover {
        background-color: $hovered-button-color;
        border: none;
      }
    }

    &:hover {
      background-color: $hovered-button-blue-color;
      border: none;
    }
  }
}

.footer {
  height: 75px;

  &__button-container {
    height: 100%;
  }
}

.rounded-button-gray {
  padding: 8px 20px;
}

@media (max-width: 767px) {
  .recovery-codes {
    width: 320px;
    margin: 28px auto 90px;

    &__hint {
      padding: 20px;
      font-size: 12px;
      line-height: 150%;
    }

    &__list {
      width: 320px;
      padding: 45px 30px;
    }
  }

  .footer {
    height: 80px;
  }
}
</style>
