<template>
  <label class="toggle-switch">
    <input
      class="toggle-switch__checkbox"
      type="checkbox"
      :checked="value"
      @click.stop.prevent="onClick"
    />
    <span class="toggle-switch__slider"></span>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 17px;

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle-switch__slider {
      background-color: rgba(255, 106, 0, 0.2);
    }

    &:focus + .toggle-switch__slider {
      box-shadow: 0 0 1px rgba(255, 106, 0, 0.2);
    }

    &:checked + .toggle-switch__slider:before {
      transform: translateX(15px);
      background-color: $orange-color;
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    cursor: pointer;

    border-radius: 8.5px;
    background: rgba(180, 191, 199, 0.2);

    transition: 0.4s;

    &:before {
      position: absolute;
      left: 2px;
      bottom: 2px;

      content: '';

      height: 13px;
      width: 13px;

      border-radius: 50%;
      background-color: $grey-color;

      transition: 0.4s;
    }
  }
}
</style>
