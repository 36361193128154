<template>
  <div class="product-price-list__container">
    <div class="flex--space-between--center product-price-list__header">
      <span class="product-price-list__header__title">
        {{ $t('dashboard.settings.priceSettings') }}, {{ currency }}
      </span>

      <div class="icon-close custom-icon" @click="checkBeforeModalClose"></div>
    </div>
    <div class="product-price-list__separator"></div>
    <div class="product-price-list__content">
      <div ref="priceContainer" class="scroll-wrapper">
        <div class="product-price-list__price-container">
          <ProductPrice
            v-for="product in productPrices"
            :key="product.id"
            :product="product"
            @price-validate="validatePrice"
            @price-update="updatePrice"
          />
        </div>
      </div>
    </div>

    <div class="product-price-list__footer">
      <a class="product-price-list__footer__cancel mr30" @click="closeModal">
        {{ $t('dashboard.action.cancel') }}
      </a>
      <button class="rounded-button-white" @click="saveChanges">
        {{ $t('dashboard.button.saveChanges') }}
      </button>
    </div>
    <unsaved-changes-modal />
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

import pageUnsavedChangesMixin from '@/modules/dashboard/components/mixins/pageUnsavedChangesMixin';

import UnsavedChangesModal from '@/modules/dashboard/components/message-templates/UnsavedChangesModal';
import ProductPrice from '@/modules/dashboard/components/price-settings/ProductPrice';

import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  MICRONEEDLING_PRODUCT_REFERENCES,
  UNSAVED_CHANGES_INPUTS
} from '@/modules/dashboard/api/constants';

export default {
  name: 'ProductPriceList',
  components: { UnsavedChangesModal, ProductPrice },
  mixins: [pageUnsavedChangesMixin],
  data() {
    return {
      productPrices: null,
      savedProductPricesState: null,
      productPricesValidation: null,
      scrollbar: null
    };
  },
  computed: {
    ...mapGetters({
      setting: dashTypes.getters.SETTING,
      currency: dashTypes.getters.GET_CURRENCY
    }),
    isPricesValid() {
      return this.productPricesValidation.every(({ isValid }) => isValid);
    }
  },
  created() {
    // TODO: remove price-filter after back-end stops sending microneedling prices
    this.productPrices = this.filterProductPrices(this.setting.productPrices);
    this.savedProductPricesState = cloneDeep(this.productPrices);
    this.productPricesValidation = this.productPrices.map(({ id }) => ({ id, isValid: true }));
  },
  mounted() {
    this.scrollbar = new PerfectScrollbar(
      this.$refs.priceContainer,
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    );
  },
  methods: {
    ...mapActions({
      updateProductPrices: dashTypes.actions.UPDATE_PRICE
    }),
    filterProductPrices(prices) {
      return prices.filter(
        ({ reference }) => !MICRONEEDLING_PRODUCT_REFERENCES.includes(reference)
      );
    },
    validatePrice(id, isValid) {
      this.productPricesValidation = this.productPricesValidation.map(product => {
        if (product.id === id) {
          return { id, isValid };
        }

        return product;
      });
    },
    updatePrice(id, chosenPrice) {
      this.productPrices = this.productPrices.map(product => {
        if (product.id === id) {
          return { ...product, chosenPrice };
        }

        return product;
      });
    },
    async saveChanges() {
      if (!this.isPricesValid) {
        return;
      }

      await this.updateProductPrices(this.productPrices);

      this.closeModal();
    },
    async checkBeforeModalClose() {
      const userAnswer = await this.onPageClose(this.productPrices, this.savedProductPricesState);

      if (!userAnswer) {
        return;
      }

      if (userAnswer !== UNSAVED_CHANGES_INPUTS.YES) {
        this.closeModal();
      }

      if (this.isPricesValid) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$modal.hide('price-settings-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.product-price-list {
  &__container {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 15px 20px 15px 15px;

    &__title {
      font-size: 15px;
      color: $text-color;
    }
  }

  &__separator {
    border-top: 1px solid $dim-white;
  }

  &__content {
    flex: 1;
    position: relative;
  }

  &__price-container {
    columns: 2;
    padding: 20px;
  }

  &__footer {
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 7px 20px;

    background-color: $background;

    &__cancel {
      color: $cancel-text-color;
    }

    .rounded-button-white {
      padding: 7px 17px;
    }
  }
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

.scroll-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media (max-width: 767px) {
  .product-price-list {
    &__price-container {
      display: flex;
      flex-flow: row wrap;
      position: relative;
      height: 440px;
      min-height: auto;
      padding: 0;
    }

    &__header {
      padding: 17px 20px;

      &__title {
        font-size: 13px;
        line-height: 15px;
      }
    }

    &__footer {
      height: 50px;

      justify-content: space-between;
    }
  }

  .scroll-wrapper {
    margin: 20px 0;
  }
}
</style>
