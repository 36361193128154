<template>
  <div>
    <button class="change-photo__button rounded-button-gray" @click="changePhoto">
      {{ $t('dashboard.button.changePhoto') }}
    </button>
    <input ref="user-photo" class="d-none" type="file" accept="image/*" @change="photoLoaded" />
    <photo-validation-modal @try-again="changePhoto" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PhotoValidationModal from '@/modules/dashboard/components/dashboard/common/PhotoValidationModal';

import { getFileType } from '@/modules/dashboard/utils';

import { compressImageFile } from '@/modules/questionnaire/utils';
import { updateDoctorPhoto } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';

import {
  MAX_PHOTO_SIZE_IN_BYTES_DOCTOR,
  MAX_PHOTO_SIZE_IN_MB_DOCTOR,
  ALLOWED_FILE_TYPE
} from '@/modules/questionnaire/api/constants';
import { PHOTO_VALIDATION_ERRORS } from '@/modules/dashboard/api/constants';

export default {
  name: 'ChangePhoto',
  components: { PhotoValidationModal },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    changePhoto() {
      this.$refs['user-photo'].click();
    },
    resetFileInput() {
      this.$refs['user-photo'].value = '';
    },
    async photoLoaded({ target: { files } }) {
      if (files.length === 0) {
        return;
      }

      const [file] = files;
      const { size: fileSize } = file;
      const [fileType] = getFileType(file);

      if (fileType !== ALLOWED_FILE_TYPE) {
        this.$modal.show('photo-validation-modal', {
          validationError: PHOTO_VALIDATION_ERRORS.TYPE_NOT_ALLOWED
        });

        this.resetFileInput();

        return;
      }

      if (fileSize > MAX_PHOTO_SIZE_IN_BYTES_DOCTOR) {
        this.$modal.show('photo-validation-modal', {
          validationError: PHOTO_VALIDATION_ERRORS.MAX_FILE_SIZE,
          maxSizeExceeds: MAX_PHOTO_SIZE_IN_MB_DOCTOR
        });

        this.resetFileInput();

        return;
      }

      this.setLoading(true);
      const compressedImage = await compressImageFile(file);

      await updateDoctorPhoto({ userId: this.userId, photo: compressedImage });

      this.$emit('photo-uploaded');
      this.setLoading(false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.change-photo__button {
  display: block;
  font-size: 15px;
  line-height: 17px;
  margin: 0 auto;
}
</style>
