<template>
  <section class="security-settings-wrapper">
    <div class="security-settings m0a">
      <div
        class="security-settings__status security-status flex-column--align-center"
        :class="securityStatus.statusClass"
      >
        <span class="custom-icon mb10" :class="securityStatus.icon"></span>
        <span>{{ $t(securityStatus.statusText) }}</span>
      </div>
      <div class="security-settings__description">
        {{ $t('dashboard.securitySettings.authenticationAdds') }}
      </div>
      <div
        v-if="hasTwoFactorAuthentication"
        class="security-settings__link link mb30"
        @click="showRecoveryCodes"
      >
        {{ $t('dashboard.label.authenticationRecoveryCodes') }}
      </div>
      <button
        class="security-settings__action-button rounded-button-orange"
        @click="handleTwoFactorAuthentication"
      >
        {{ $t(securityStatus.buttonText) }}
      </button>
    </div>
    <two-factor-authentication-modals />
    <authentication-confirm-modal />
    <authentication-disable-modal @disable-authentication="disableTwoFactorAuthentication" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TwoFactorAuthenticationModals from '@/modules/dashboard/components/two-factor-authentication/TwoFactorAuthenticationModals';
import AuthenticationConfirmModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationConfirmModal';
import AuthenticationDisableModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationDisableModal';

import { updateUser } from '@/api';
import { handleRoutingInfo } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import {
  TWO_FACTOR_AUTHENTICATION_SET_UP_ROUTING_INFO,
  RECOVERY_CODES_ROUTING_INFO
} from '@/modules/dashboard/api/constants';

const TWO_FACTOR_AUTHENTICATION_STATUSES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

const TWO_FACTOR_AUTHENTICATION_CONFIG = {
  [TWO_FACTOR_AUTHENTICATION_STATUSES.ENABLED]: {
    icon: 'icon-shield-check',
    statusText: 'dashboard.securitySettings.authenticationEnabled',
    statusClass: 'security-status--enabled',
    buttonText: 'dashboard.label.disableAuthentication'
  },
  [TWO_FACTOR_AUTHENTICATION_STATUSES.DISABLED]: {
    icon: 'icon-shield-disable',
    statusText: 'dashboard.securitySettings.authenticationNotEnabled',
    statusClass: 'security-status--disabled',
    buttonText: 'dashboard.label.enableAuthentication'
  }
};

export default {
  name: 'SecuritySettings',
  components: {
    TwoFactorAuthenticationModals,
    AuthenticationConfirmModal,
    AuthenticationDisableModal
  },
  computed: {
    ...mapGetters({
      user: rootTypes.getters.GET_USER,
      userId: rootTypes.getters.GET_USER_ID
    }),
    hasTwoFactorAuthentication() {
      const { twoFactorAuthenticationEnabled } = this.user;

      return twoFactorAuthenticationEnabled;
    },
    twoFactorAuthenticationStatus() {
      return this.hasTwoFactorAuthentication
        ? TWO_FACTOR_AUTHENTICATION_STATUSES.ENABLED
        : TWO_FACTOR_AUTHENTICATION_STATUSES.DISABLED;
    },
    securityStatus() {
      return TWO_FACTOR_AUTHENTICATION_CONFIG[this.twoFactorAuthenticationStatus];
    }
  },
  methods: {
    ...mapActions({
      setRecoveryCodes: rootTypes.actions.SET_USER_RECOVERY_CODES,
      getUpdatedUser: rootTypes.actions.UPDATE_USER
    }),
    showRecoveryCodes() {
      this.setRecoveryCodes(this.userId);

      handleRoutingInfo(
        {
          ...RECOVERY_CODES_ROUTING_INFO,
          params: { isHeaderWithBackButton: false, hasAuthenticationEnabled: true }
        },
        this.$router,
        this.$modal
      );
    },
    handleTwoFactorAuthentication() {
      if (this.hasTwoFactorAuthentication) {
        this.$modal.show('authentication-disable-modal');

        return;
      }

      handleRoutingInfo(
        {
          ...TWO_FACTOR_AUTHENTICATION_SET_UP_ROUTING_INFO,
          params: { isHeaderWithBackButton: false }
        },
        this.$router,
        this.$modal
      );
    },
    async disableTwoFactorAuthentication() {
      await updateUser({ ...this.user, twoFactorAuthenticationEnabled: false });

      await this.getUpdatedUser(this.userId);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/css/common/tooltips.css';

.security-settings-wrapper {
  width: 100%;
  padding-bottom: 40px;
}

.security-settings {
  width: 300px;
  text-align: center;

  &__description {
    margin-bottom: 32px;
    font-weight: 300;
    font-size: 13px;
    line-height: 19.5px;
    color: $text-color;
  }

  &__link {
    color: $links-color;
  }

  &__action-button {
    border: 1px solid $light-blue;
  }
}

.security-status {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;

  &--enabled {
    color: $green-color-success;
  }

  &--disabled {
    color: $main-title-color;
  }
}
</style>
