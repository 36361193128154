<template>
  <TemplateWithHeader>
    <div class="profile">
      <div class="templates__breadcrumbs">
        <span class="breadcrumbs__link">
          <router-link :to="{ name: 'Dashboard' }" :title="$t('dashboard.action.home')">
            <span @click="resetOpenedPatient">{{ $t('dashboard.action.home') | upperCase }}</span>
          </router-link>
        </span>
        /
        <span class="breadcrumbs__current no-search-field">
          {{ $t('dashboard.label.myAccount') }}
        </span>
      </div>
      <div class="profile__doctor-info doctor-info">
        <tabs
          :tabs="tabs"
          :active-tab="activeTab"
          tabs-align="flex-start"
          is-labels-are-keys
          @select-tab="selectTab"
        >
          <div slot="tab-content">
            <component :is="activeTab"></component>
          </div>
        </tabs>
      </div>

      <doctor-statistics :doctor-id="userId"></doctor-statistics>
    </div>
  </TemplateWithHeader>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import Tabs from '@/modules/dashboard/components/common/Tabs';
import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';
import DoctorStatistics from '@/modules/dashboard/components/profile/DoctorStatistics';
import PersonalInfo from '@/modules/dashboard/components/profile/PersonalInfo';
import SecuritySettings from '@/modules/dashboard/components/profile/SecuritySettings';

import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { DOCTOR_PROFILE_TAB_NAMES } from '@/modules/dashboard/api/constants';

const TABS_BINDING = [
  new TabInfo(
    DOCTOR_PROFILE_TAB_NAMES.PERSONAL_INFO,
    'dashboard.profile.personalInfoTab',
    'PersonalInfo'
  ),
  new TabInfo(
    DOCTOR_PROFILE_TAB_NAMES.SECURITY_SETTINGS,
    'dashboard.profile.securitySettingsTab',
    'SecuritySettings'
  )
];

export default {
  name: 'Profile',
  components: {
    TemplateWithHeader,
    Tabs,
    DoctorStatistics,
    PersonalInfo,
    SecuritySettings
  },
  data() {
    return {
      tabs: TABS_BINDING,
      isMobileDevice: isMobileDevice(),
      activeTab: 'PersonalInfo'
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    })
  },
  methods: {
    ...mapActions({
      resetOpenedPatient: types.actions.RESET_OPENED_PATIENT
    }),
    selectTab(selectedTabName) {
      this.activeTab = selectedTabName;
    }
  },
  metaInfo: {
    title: 'My account',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/helpers/colors';
@import '../../../assets/css/common/icons.css';

.profile {
  width: 100%;
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;
  display: flex;
  flex-direction: column;

  &__doctor-info {
    background-color: $white;
  }
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .profile {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media screen and (max-width: 767px) {
  .profile {
    font-size: 13px;
    line-height: 15px;
  }

  .otherXS {
    display: none;
  }
}

@media screen and (min-width: 767px) {
  .profile {
    margin-bottom: 170px;
  }

  .onlyXS {
    display: none;
  }
}

.templates {
  &__container {
    /deep/ .tabs {
      width: 100%;

      justify-content: flex-start;
    }
  }

  &__breadcrumbs {
    margin: 30px 0;

    font-size: 15px;
    font-weight: bold;
    color: $grey-color;
  }

  &__tabs {
    display: flex;
  }
}

.breadcrumbs {
  &__current {
    font-size: 18px;
    color: $main-title-color;
  }
}

@media (max-width: 767px) {
  .templates {
    &__container {
      /deep/ .tabs .tab {
        &:first-of-type {
          margin-right: 0;
        }
      }
    }

    &__breadcrumbs {
      margin: 15px 0 15px 15px;

      font-size: 12px;
    }
  }

  .breadcrumbs {
    &__current {
      font-size: 14px;
    }
  }
}
</style>
