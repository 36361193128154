<template>
  <div v-if="isAvailable" class="product-price__container mb10">
    <div class="product-price__icon product-header-icon" :class="iconClasses">
      {{ productLetter }}
    </div>
    <div class="product-price__input-container ml10">
      <div class="product-price__info" :class="productPriceInfoClasses">
        <div class="product-price__name">{{ $t(productName) }}</div>
        <div v-if="productDescription" class="product-price__volume">{{ productDescription }}</div>
      </div>
      <input
        ref="input"
        v-model.number="price"
        v-tooltip="{
          content: tooltipMessage,
          classes: 'custom-tooltip',
          placement: 'bottom'
        }"
        v-allow-only-reg-exp="/^([0-9]|[.,])$/"
        class="form-control product-price__input input-focus-within"
        type="number"
        step="0.1"
        :min="product.minPrice"
        :max="product.maxPrice"
        :class="{ notValid: !valid }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  PRODUCT_REFERENCES,
  PRODUCT_LETTERS,
  PRICE_PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP
} from '@/modules/dashboard/api/constants';

const ACTIVE_UNIT_REFERENCES = [
  PRODUCT_REFERENCES.ACTIVE_UNIT,
  PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO
];

const ACTIVE_UNITS_WEBSHOP_DESCRIPTION = {
  [PRODUCT_REFERENCES.ACTIVE_UNIT]: 'dashboard.subtitle.unitPrice',
  [PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO]: 'dashboard.subtitle.unitPricePromo'
};

const getVolumeUnits = productReference => {
  const gramProducts = [PRODUCT_REFERENCES.NEXULTRA_UV, PRODUCT_REFERENCES.NEXULTRA_L_BRUSH];

  if (gramProducts.includes(productReference)) {
    return 'dashboard.label.gramm';
  }

  return 'dashboard.label.ml';
};

export default {
  name: 'ProductPrice',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: true
    };
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS
    }),
    iconClasses() {
      return {
        'icon__active-unit': this.isActiveUnit,
        icon__microneedling: this.isMicroneedling
      };
    },
    price: {
      get() {
        return this.product.chosenPrice;
      },
      set(value) {
        this.updatePrice(value);
      }
    },
    isActiveUnit() {
      return ACTIVE_UNIT_REFERENCES.includes(this.product.reference);
    },
    isPromoActiveUnit() {
      return this.product.reference === PRODUCT_REFERENCES.ACTIVE_UNIT_PROMO;
    },
    isWebshopActiveUnit() {
      return this.isActiveUnit && this.hasWebshopAccess;
    },
    isAvailable() {
      return !this.isPromoActiveUnit || this.hasWebshopAccess;
    },
    productPriceInfoClasses() {
      return {
        'info__active-unit': this.isActiveUnit,
        'info__webshop-active-unit': this.isWebshopActiveUnit
      };
    },
    tooltipMessage() {
      const minimumPriceLabel = this.$t('dashboard.priceSettings.minimumPrice');
      const maximumPriceLabel = this.$t('dashboard.priceSettings.maximumPrice');

      const minPriceMessage = `${minimumPriceLabel} - ${this.product.minPrice} ${this.currency}`;
      const maxPriceMessage = `${maximumPriceLabel} - ${this.product.maxPrice} ${this.currency}`;

      return `${minPriceMessage}<br>${maxPriceMessage}`;
    },
    productName() {
      return PRICE_PRODUCT_REFERENCE_TO_DISPLAY_TITLE_MAP[this.product.reference];
    },
    productDescription() {
      const { reference, volume } = this.product;

      if (this.isActiveUnit && this.hasWebshopAccess) {
        return this.$t(ACTIVE_UNITS_WEBSHOP_DESCRIPTION[reference]);
      }

      if (!volume) {
        return '';
      }

      const volumeLabel = this.$t(getVolumeUnits(reference));

      return `${volume}${volumeLabel}${reference === PRODUCT_REFERENCES.SERUM_X2_UNIT ? '×2' : ''}`;
    },
    productLetter() {
      return PRODUCT_LETTERS[this.product.reference];
    }
  },
  methods: {
    updatePrice(value) {
      const { id } = this.product;
      const newPrice = this.getRoundedValue(value);

      this.valid = !this.isPriceOutOfRange(newPrice);

      this.$emit('price-validate', id, this.valid);
      this.$emit('price-update', id, newPrice);
    },
    isPriceOutOfRange(newPrice) {
      return this.product.minPrice > newPrice || newPrice > this.product.maxPrice;
    },
    getRoundedValue(value) {
      return +(Math.round(value * 100) / 100).toFixed(1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/products';
@import '../../../../assets/css/common/tooltips.css';
@import '../../../../assets/css/common/icons.css';

.product-price {
  &__container {
    display: flex;
    break-inside: avoid;
  }

  &__input-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__icon {
    margin-top: 3px;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
  }

  &__name {
    font-size: 13px;
    line-height: 13px;
    color: $main-title-color;
  }

  &__volume {
    font-size: 10px;
    line-height: 12px;
    margin-top: 5px;

    color: $grey-color;
  }

  &__input {
    width: 100px;
    height: auto;
    padding: 8px 10px;
    flex-shrink: 0;
    align-self: flex-start;

    border: 0.75px solid $light-blue;
    border-radius: 5px;
    font-size: 16px;
    line-height: 18px;
    color: $main-title-color;
  }
}

.icon {
  &__active-unit {
    background: $pink-color;
    border-radius: 10px;
  }

  &__microneedling {
    background-color: transparent;
  }
}

.info {
  &__active-unit {
    margin-top: 0;
    justify-content: center;
  }

  &__webshop-active-unit {
    margin-top: 3px;
    justify-content: flex-start;
    min-height: 44px;
  }
}

.notValid {
  border: 0.75px $error-color solid;
}

@media (max-width: 767px) {
  .product-price {
    &__container {
      width: 100%;
      margin-bottom: 20px;
      padding: 0 20px;
    }
  }
}

@media (max-width: 575px) {
  .product-price {
    &__input {
      width: 70px;
    }
  }
}
</style>
