<template>
  <div>
    <div ref="dropdown" class="bell-notifications otherXS">
      <div
        class="dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="toggleDropdownVisibility"
      >
        <notifications-counter :counter="notificationsCounter" />
      </div>
      <div
        ref="dropdownMenu"
        class="bell-notifications__dropdown dropdown-menu dropdown-menu-right"
        :class="{ 'bell-notifications--opened': isDropdownOpened }"
      >
        <notification-list
          :key="`dashboard-notifications-list-${notificationsCounter}`"
          @updateBell="updateCounter"
        ></notification-list>
      </div>
    </div>
    <div class="onlyXS" @click="showNotificationList">
      <notifications-counter :counter="notificationsCounter" />
    </div>
    <notification-list-modal
      :key="`mobile-notification-lists-${notificationsCounter}`"
      @updateBell="updateCounter"
    ></notification-list-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import NotificationList from '@/modules/dashboard/components/notifications/NotificationList';
import NotificationListModal from '@/modules/dashboard/components/notifications/NotificationListModal';
import NotificationsCounter from '@/modules/dashboard/components/home/NotificationsCounter';

import { legacyDropdownMixin } from '@/modules/dashboard/mixins/dropdown/legacyDropdownMixin';

import { addPixelsPostfix } from '@/modules/questionnaire/utils';

import { types as dashTypes } from '@/modules/dashboard/store/types';

const DROPDOWN_WIDTH = 500;
const DROPDOWN_RIGTH_OFFSET = 130;
const DROPDOWN_LEFT_SIDE_MARGIN = 20;

export default {
  name: 'BellNotifications',
  components: { NotificationsCounter, NotificationListModal, NotificationList },
  mixins: [legacyDropdownMixin],
  computed: {
    ...mapGetters({
      notificationsCounter: dashTypes.getters.NOTIFICATIONS_COUNTER
    })
  },
  async mounted() {
    await this.updateCounter();
  },
  methods: {
    ...mapActions({
      updateNotificationsCounter: dashTypes.actions.UPDATE_NOTIFICATIONS_COUNTER
    }),
    showNotificationList() {
      this.$modal.show('notification-list-modal');
    },
    async updateCounter() {
      await this.updateNotificationsCounter();
    },
    async toggleDropdownVisibility(event) {
      this.toggleDropdown(event);

      const { dropdown, dropdownMenu } = this.$refs;
      const { left } = dropdown.getBoundingClientRect();
      const isTabletResolution = window.matchMedia('(min-width: 768px) and (max-width: 991px)');

      const dropdownLeftOffset = isTabletResolution
        ? DROPDOWN_WIDTH - DROPDOWN_RIGTH_OFFSET
        : DROPDOWN_WIDTH;

      const widthToLeftSide = left - dropdownLeftOffset;
      const hasEnoughLeftSideMargin = widthToLeftSide > DROPDOWN_LEFT_SIDE_MARGIN;

      if (!hasEnoughLeftSideMargin) {
        let dropdownRight = Math.abs(widthToLeftSide) + DROPDOWN_LEFT_SIDE_MARGIN;

        if (isTabletResolution) {
          dropdownRight += DROPDOWN_RIGTH_OFFSET;
        }

        dropdownMenu.style.right = addPixelsPostfix(-dropdownRight);

        return;
      }

      dropdownMenu.style.right = isTabletResolution ? '-130px' : '-7px';
    }
  }
};
</script>

<style lang="scss" scoped>
.bell-notifications {
  position: relative;

  &--opened {
    display: block;
  }
}

.dropdown-menu {
  width: 500px;
  max-height: 600px;
  overflow: visible;

  margin-top: 15px;
  padding: 0;

  border-radius: 8px;
  box-shadow: 0 6px 10px rgba(148, 166, 176, 0.3);
}
</style>
