<template>
  <div class="notification-counter__wrapper">
    <img
      class="icon-bell"
      src="@/assets/images/dashboard/icons/new-bell-icon.svg"
      alt="Bell"
    />
    <div class="notification-counter" :class="{ hide: counter === 0 }">
      <span class="notification-counter__text">
        {{ counter }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationsCounter',
  props: {
    counter: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
.icon-bell {
  margin-top: 8px;
}

.notification-counter {
  position: absolute;
  left: 10px;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  min-width: 16px;
  height: 16px;

  background: #ed5158;
  border: 2px solid #f0f3f5;
  border-radius: 50%;
  box-sizing: content-box;
}

.notification-counter__text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  padding-left: 3px;
  padding-right: 3px;

  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

.notification-counter__wrapper {
  position: relative;
  cursor: pointer;
}
</style>
