<template>
  <div class="profile__statistics">
    <div class="statistics__title">
      {{ $t('dashboard.label.statistics') }}
    </div>
    <div class="statistics__container">
      <div class="patient-counter__container counter__container">
        <div class="patient-counter counter-number">{{ statistics.patients }}</div>
        <div class="counter-title">
          {{ $t('dashboard.statistics.patients') }}
        </div>
      </div>
      <div class="new-patients-counter__container counter__container">
        <div class="new-patients-counter counter-number">+{{ statistics.patientsInLastDays }}</div>
        <div class="new-patients-counter__title counter-title">
          <span class="pre-line">{{ $t('dashboard.statistics.newPatients') }}</span>
        </div>
      </div>
      <br class="onlyXS" />
      <div class="treatments-counter__container counter__container">
        <div class="treatments-counter counter-number">{{ statistics.treatments }}</div>
        <div class="counter-title">
          {{ $t('dashboard.statistics.treatments') }}
        </div>
      </div>
      <div class="diagnostics-counter__container counter__container">
        <div class="patient-counter counter-number">{{ statistics.diagnostics }}</div>
        <div class="counter-title">
          {{ $t('dashboard.statistics.diagnostics') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDoctorStatistics } from '@/modules/dashboard/api';

export default {
  name: 'DoctorStatistics',
  props: {
    doctorId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      statistics: {
        diagnostics: 0,
        treatments: 0,
        patientsInLastDays: 0,
        patients: 0
      }
    };
  },
  async created() {
    const { data } = await getDoctorStatistics({ doctorId: this.doctorId });
    this.statistics = data;
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.profile__statistics {
  width: 100%;
  padding-top: 53px;
  background-color: $background;
  display: flex;
  flex-direction: column;
}

.statistics__title {
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.statistics__container {
  display: flex;
  justify-content: space-around;
}

.counter-number {
  font-size: 48px;
  line-height: 55px;
  color: $active-title-color;
  margin-bottom: 16px;
}

.counter__container {
  display: flex;
}

.counter__container {
  display: flex;
  flex-direction: column;
}

.counter-number,
.counter-title {
  text-align: center;
}

.counter-title {
  font-weight: lighter;
  color: $grey-color;
}

.new-patients-counter__title {
  word-break: break-word;
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .profile__statistics {
    padding-bottom: 74px;
  }

  .statistics__container {
    display: block;
    vertical-align: top;
  }

  .counter__container {
    display: inline-flex;
    align-items: center;
    width: 49%;
  }

  .treatments-counter {
    margin-top: 32px;
  }

  .counter-number {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 30px;
  }
}
</style>
