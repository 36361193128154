<template>
  <modal
    :name="name"
    :width="modalWidth"
    height="auto"
    :classes="['modal-window']"
    :click-to-close="false"
    adaptive
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="activation-modal modal-container flex-column flex--center--center">
      <slot name="header">
        <h2 class="activation-modal__title">{{ $t(title) }}</h2>
      </slot>

      <slot name="content">
        <p class="activation-modal__description">
          {{ $t(description) }}
        </p>
      </slot>

      <slot name="actions">
        <div class="activation-modal__actions activation-actions flex--center--center">
          <button
            class="activation-actions__cancel cancel-button pointer"
            @click="$emit('cancel', $event)"
          >
            {{ $t(cancelText) }}
          </button>
          <button
            class="activation-actions__confirm rounded-button-white"
            @click="$emit('confirm', $event)"
          >
            {{ $t(confirmText) }}
          </button>
        </div>
      </slot>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'ActivationModal',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    modalWidth: {
      type: String,
      default: '545px'
    },
    cancelText: {
      type: String,
      default: 'dashboard.action.cancel'
    },
    confirmText: {
      type: String,
      default: 'dashboard.action.yesAgree'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';

.activation-modal {
  margin: 0 3%;

  &__title {
    margin: 50px 8% 12px;

    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $main-title-color;
  }

  &__description {
    margin: 0 8% 24px;

    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $text-color;
  }

  &__actions {
    margin-bottom: 50px;
  }
}

.activation-actions {
  &__cancel {
    margin-right: 16px;
  }
}

@media (min-width: 767px) {
  .activation-modal {
    margin: 0;

    &__description {
      margin: 0 12% 24px;
    }
  }
}
</style>
