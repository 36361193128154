<template>
  <div key="search-field" class="search-field-container flex--center--center">
    <div
      class="search-field"
      :class="{ 'search-field--focused': isSearchFieldClassApplied || filter }"
      @click="applyFilter"
    >
      <span class="search-field__left-side" @click="setSearchFieldClass(true)">
        <span class="icon-search search-field__icons" aria-hidden="true" />
      </span>
      <input
        ref="filterInput"
        v-model="filter"
        type="text"
        class="form-control header-text search-field__name-email"
        :placeholder="$t('dashboard.info.searchByNameEmail')"
        @blur="setSearchFieldClass(false)"
      />
      <span class="search-field__right-side">
        <a
          v-show="isHeaderSearch || filter"
          :title="$t('dashboard.action.close')"
          @click="onCrossIconClick"
        >
          <span class="icon-cross search-field__icons" aria-hidden="true"></span>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'SearchField',
  props: {
    isHeaderSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSearchFieldClassApplied: false
    };
  },
  computed: {
    ...mapGetters({
      partialName: dashTypes.getters.PARTIAL_NAME,
      patientFilter: dashTypes.getters.PATIENT_FILTER
    }),
    filter: {
      get() {
        return this.partialName;
      },
      set(value) {
        this.updateFilter(value);
      }
    }
  },
  beforeDestroy() {
    this.resetFilter();
  },
  methods: {
    ...mapActions({
      updateFilter: dashTypes.actions.UPDATE_FILTER,
      updatePatientFilterCount: dashTypes.actions.UPDATE_PATIENT_FILTER_COUNT
    }),
    focusInput() {
      this.$refs.filterInput.focus();
    },
    setSearchFieldClass(isClassApplied) {
      this.isSearchFieldClassApplied = isClassApplied;

      if (isClassApplied) {
        this.focusInput();
      }
    },
    async applyFilter() {
      const { statuses = [], clinics = [], sources = [], influencers = [] } = this.patientFilter;

      await this.updatePatientFilterCount({ statuses, clinics, sources, influencers });
    },
    onCrossIconClick() {
      this.$emit('cross-icon-click');
    },
    resetFilter() {
      this.updateFilter('');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
.search-field-container {
  width: 100%;
}

.search-field {
  position: relative;
  display: table;

  width: 85%;

  border-collapse: separate;
  border: 1px solid $active;
  border-radius: 50px;
  background-color: $white;

  &__name-email {
    line-height: 34px;
    height: 34px;
    font-size: 14px;
    border-radius: 4px;
  }

  .form-control {
    width: 100%;
    height: 38px;
    font-size: 16px;
    line-height: 19px;
  }

  .search-field__right-side {
    width: 33px;
    padding-right: 15px;
    display: table-cell;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    border: none;

    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  &__left-side {
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    color: #aebcc4;
    border: none;
    padding-right: 10px;
    padding-left: 15px;

    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &__icons {
    position: relative;
    top: 1px;
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  &:hover {
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .search-field-container {
    width: fit-content;
  }

  .search-field {
    position: relative;
    display: table;
    width: 40px;
    border-collapse: separate;
    border: 1px solid transparent;
    background-color: transparent;

    .form-control,
    &__right-side {
      width: 0;
      padding: 0;
    }

    &--focused {
      width: 250px;
      border: 1px solid $active;
      border-radius: 50px;
      background-color: $white;

      .form-control {
        width: 100%;
        height: 34px;
        font-size: 14px;
        line-height: 18px;
      }

      .search-field__right-side {
        width: 33px;
        padding-right: 15px;
      }
    }

    &__left-side {
      padding: 6px 12px 6px 8px;
    }
  }
}
</style>
