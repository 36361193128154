<template>
  <modal
    name="authentication-disable-modal"
    :classes="['modal-window']"
    transition="scale"
    :click-to-close="false"
    :width="modalWidth"
    :height="modalHeight"
  >
    <div class="modal-container">
      <div class="authentication-disable">
        <div class="authentication-disable__content content">
          <span class="content__icon icon-shield-disable"></span>
          <span class="content__text content__text--gray">
            {{ $t('dashboard.authentication.disableAuthentication') }}
          </span>
        </div>
        <div class="authentication-disable__actions">
          <div class="actions-buttons flex space-around">
            <button class="cancel-button actions-buttons__cancel" @click="onClose">
              {{ $t('dashboard.action.cancel') }}
            </button>
            <button
              class="rounded-button-white actions-buttons__continue"
              @click="disableAuthentication"
            >
              {{ $t('dashboard.label.yesDisable') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'AuthenticationDisableModal',
  data() {
    return {
      modalWidth: '500px',
      modalHeight: '265px',
      isMobile: isMobileDevice()
    };
  },
  created() {
    if (this.isMobile) {
      this.modalWidth = '328px';
    }
  },
  methods: {
    onClose() {
      this.$modal.hide('authentication-disable-modal');
    },
    disableAuthentication() {
      this.$emit('disable-authentication');
      this.$modal.hide('authentication-disable-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.authentication-disable {
  padding: 70px 70px 60px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;
  }
}

.content {
  font-size: 16px;
  line-height: 19px;

  &__icon {
    width: 30px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  &__text {
    &--disabled {
      color: $text-color;
    }
  }
}
.actions-buttons {
  width: 260px;
  margin: 0 auto;
  font-size: 15px;
  line-height: 17px;

  &__cancel {
    font-size: 15px;
    line-height: 17px;
  }

  &__continue {
    font-size: 15px;
    line-height: 17px;
  }
}

@media (max-width: 767px) {
  .modal-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .authentication-disable {
    padding: 10px;
    text-align: center;
  }
}
</style>
