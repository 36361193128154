<template>
  <div class="tag-selector__container">
    <div
      class="tag-selector rounded-input input-focus-within flex--align-center"
      :class="{ 'error-highlight': isTagInputHasErrors }"
    >
      <label class="tag-selector__label flex--align-center" for="add-tag-input">
        <slot name="tag-icon"></slot>
        <div v-for="(tag, index) in tags" :key="index" class="tag-selector__tag flex--align-center">
          <span class="tag-selector__tag__text ellipsized">{{ tag }}</span>
          <div class="icon-close pointer" @click="deleteTag(index)"></div>
        </div>
        <input
          id="add-tag-input"
          v-model="newTag"
          v-validate.disable="{ regex: regExp }"
          name="tag-input"
          class="tag-selector__input main-placeholder transparent-input"
          :placeholder="placeholderText"
          :class="{ none: isMaxNumberOfTags, 'error-message': isTagInputHasErrors }"
          @blur="addTag"
          @keypress.enter.space.prevent="addTag"
        />
      </label>
    </div>
    <field-error v-if="isRegExpError" :error-message="errorMessage"></field-error>
    <field-error
      v-else-if="isValidationError"
      :error-message="validationErrorMessage"
    ></field-error>
  </div>
</template>

<script>
import FieldError from '@/modules/dashboard/components/common/FieldError';

const TAG_INPUT_NAME = 'tag-input';

export default {
  name: 'TagSelector',
  components: { FieldError },
  inject: ['$validator'],
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    regExp: {
      type: RegExp,
      default: () => /^[a-zA-Z0-9]*$/
    },
    maxNumberOfTags: {
      type: Number,
      default: 999
    },
    valid: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: 'The tag has validation problem'
    },
    validationErrorMessage: {
      type: String,
      default: 'The field is not valid'
    },
    placeholder: {
      type: String,
      default: 'dashboard.button.addAnEmail'
    },
    isPlaceholderKey: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newTag: ''
    };
  },
  computed: {
    isTagInputHasErrors() {
      return this.isRegExpError || this.isValidationError;
    },
    isRegExpError() {
      return this.errors.has(TAG_INPUT_NAME);
    },
    isValidationError() {
      return !this.valid;
    },
    isMaxNumberOfTags() {
      return this.tags.length >= this.maxNumberOfTags;
    },
    placeholderText() {
      return this.isPlaceholderKey ? this.$t(this.placeholder) : this.placeholder;
    }
  },
  methods: {
    async addTag() {
      if ((await this.$validator.validate(TAG_INPUT_NAME)) && this.newTag) {
        this.$emit('add-tag', this.newTag);
        this.newTag = '';
      }
    },
    deleteTag(tagIndex) {
      this.$emit('delete-tag', tagIndex);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/errors.css';

.tag-selector {
  padding: 0 12px;
  flex-wrap: wrap;
  width: 100%;
  min-height: 36px;

  &__tag {
    border: 1px solid $dim-white;
    border-radius: 15px;
    padding: 5px 8px 5px 10px;
    margin: 6px 10px 6px 0;
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;

    &__text {
      max-width: 230px;
    }
  }

  &__input {
    padding: 5px 8px 5px 0;
    margin: 6px 0;
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;

    &::placeholder {
      font-size: 12px;
      font-weight: 300;
    }
  }

  &__label {
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
}

.icon-close {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .tag-selector__input {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }
}
</style>
