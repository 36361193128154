<template>
  <activation-modal
    name="autopilot-toggle-modal"
    :title="modalConfig.title"
    :description="modalConfig.description"
    cancel-text="dashboard.universkinSupport.modal.cancel"
    :confirm-text="modalConfig.confirmActionLabel"
    @cancel="onCancel"
    @confirm="onConfirm"
    @before-open="onBeforeOpen"
  />
</template>

<script>
import ActivationModal from '@/modules/dashboard/components/common/activation-modal/ActivationModal';

import { TOGGLE_ACTIONS } from '@/modules/dashboard/api/constants';

const AUTOPILOT_MODAL_CONFIG = {
  [TOGGLE_ACTIONS.ACTIVATE]: {
    title: 'dashboard.universkinSupport.modal.activateTitle',
    description: 'dashboard.universkinSupport.modal.activateDescription',
    confirmActionLabel: 'dashboard.universkinSupport.modal.activateButton'
  },
  [TOGGLE_ACTIONS.DEACTIVATE]: {
    title: 'dashboard.universkinSupport.modal.deactivateTitle',
    description: 'dashboard.universkinSupport.modal.deactivateDescription',
    confirmActionLabel: 'dashboard.universkinSupport.modal.deactivateButton'
  }
};

export default {
  name: 'AutopilotToggleModal',
  components: { ActivationModal },
  data() {
    return {
      onClose: null,
      actionName: TOGGLE_ACTIONS.ACTIVATE
    };
  },
  computed: {
    modalConfig() {
      return AUTOPILOT_MODAL_CONFIG[this.actionName];
    }
  },
  methods: {
    onBeforeOpen({ params: { onClose, actionName } }) {
      this.onClose = onClose;
      this.actionName = actionName;
    },
    onCancel() {
      this.onClose({ confirm: false });
    },
    onConfirm() {
      this.onClose({ confirm: true });
    }
  }
};
</script>
