import { isEqual } from 'lodash';

import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';

const pageUnsavedChangesMixin = {
  methods: {
    async onPageClose(updatedState, savedState) {
      if (!isEqual(updatedState, savedState)) {
        const saveChangesInput = await this.getSaveChangesInput();

        switch (saveChangesInput) {
          case UNSAVED_CHANGES_INPUTS.YES: {
            await this.saveChanges();

            return UNSAVED_CHANGES_INPUTS.YES;
          }

          case UNSAVED_CHANGES_INPUTS.NO: {
            return UNSAVED_CHANGES_INPUTS.NO;
          }

          default: {
            return false;
          }
        }
      }

      return true;
    },
    getSaveChangesInput() {
      return new Promise(resolve =>
        this.$modal.show('unsaved-changes-modal', { resolveAnswer: resolve })
      );
    }
  }
};

export default pageUnsavedChangesMixin;
