<template>
  <div class="flex--space-between--center header__dropdown-containter">
    <div class="photo__container" :style="doctorPhotoStyles"></div>
    <div class="header__doctor-name-container">
      <div>{{ doctorFullName }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import rootTypes from '@/store/types';
import { buildDoctorImageURL } from '@/modules/dashboard/utils';

export default {
  name: 'ProfileButton',
  computed: {
    ...mapGetters({
      user: rootTypes.getters.GET_USER,
      doctorFullName: rootTypes.getters.USER_FULL_NAME
    }),
    doctorPhotoStyles() {
      return { 'background-image': `url(${buildDoctorImageURL(this.user)})` };
    }
  }
};
</script>

<style scoped>
@import '../../../../assets/css/common/photos.css';

.header__dropdown-containter {
  color: #000000;
  cursor: default;
}

.photo__container {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-right: 15px;
}

.header__doctor-name-container {
  display: flex;

  align-items: center;
}

@media (max-width: 767px) {
  .header__doctor-name-container,
  .photo__container {
    display: none;
  }
}
</style>
